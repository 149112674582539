import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseHtmlContent',
    pure: false
})
export class ParseHtmlContentPipe implements PipeTransform
{

    FIELD_REG_EXP = /([\s\S]*)$([\s\S]*)/g;
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string} property
     * @returns {any}
     */
    transform(textToReplace: string = '', recordData: any = {}, regExp: any = /{{([\s\S]*?)}}/g): any
    {
        return textToReplace.replace(regExp, (_match, contents, _offset, _input_string) => {
            if (contents && contents.includes('$')) {
                const processingData = contents.split('$');
                if (processingData.length === 2) {
                    return this.returnFormattedData(processingData[0], recordData[processingData[1]]);
                } else {
                    return '';
                }
            } else {
                return recordData[contents] || '';
            }
        });
    }

    returnFormattedData(fieldType: string = '', data: any) {
        switch (fieldType) {
            case 'recCheckList':
                let result = '';
                if (Array.isArray(data)) {
                    result = '<ul>';
                    data = data || [];
                    data.forEach((list) => {
                        result = `${result}<li> ${list.name} <//li><ul>`;
                        list.checkItems = list.checkItems || [];
                        list.checkItems.forEach((checkList) => {
                            if (checkList && checkList.checked) {
                                result = `${result}<li><strike>${checkList.name}</strike></li>`;
                            } else {
                                result = `${result}<li>${checkList.name}</li>`;
                            }
                        });
                        result = `${result}</ul>`;

                    });
                    result = `${result}</ul>`;
                }
                return result   ;
            default:
                return data;
        }
    }
 }
