<div class="px-24">


    <div fxLayout="row" class="mt-8">
        <div>
            <button mat-raised-button (click)="closeDialog()">
                Cancel
            </button>
        </div>
        <span fxFlex></span>

        <div>
            <h2 class="m-0">
                {{ (templateData && templateData.title) }}
            </h2>
        </div>
        <span fxFlex></span>
        <div>
            <button (click)="showInputs=!showInputs" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                <mat-icon>more_vert</mat-icon>
            </button>

            <button *ngIf="operation != 'add'" mat-raised-button color="warn" (click)="saveData(true)" class="mr-12">
                Update
            </button>
            <button mat-raised-button color="warn" (click)="saveData()">
                {{ (operation == "add")? 'Add New': 'Update and Close'}}
            </button>
        </div>
    </div>

    <div *ngIf="showInputs" class="mt-12" fxLayout="row" fxLayoutAlign="space-between start">
        <mat-form-field floatLabel="always" appearance="outline" fxFlex="25" class="happ-small-form-field-outline">
            <input matInput [(ngModel)]="templateData.title" placeholder="Template Name">
        </mat-form-field>
        
        <mat-form-field floatLabel="always" appearance="outline" fxFlex="25" class="happ-small-form-field-outline">
            <input matInput [(ngModel)]="templateData.templateImg" placeholder="Template Img">
        </mat-form-field>

        <mat-form-field floatLabel="always" appearance="outline" fxFlex="25" class="happ-small-form-field-outline">
            <input matInput [(ngModel)]="templateData.category" placeholder="Template Category">
        </mat-form-field>

        <mat-form-field floatLabel="always" appearance="outline" fxFlex="25" class="happ-small-form-field-outline">
            <input matInput [(ngModel)]="templateData.shortSymbol" placeholder="Template Short Symbol">
        </mat-form-field>
        <mat-form-field fxFlex="25"  floatLabel="always" appearance="outline" class="happ-small-form-field-outline" >
            <mat-label>Template Type</mat-label>
            <mat-select (selectionChange)="typeChanged()" [(ngModel)]="templateData.type" >
                <mat-option value="recordTemplate">
                    Record Template
                </mat-option>
                <mat-option value="gridTemplate">
                    One Row Grid Report Template
                </mat-option>
                <mat-option value="exPageTemplate">
                    External page template
                </mat-option>
                <mat-option value="ssNotificationTemplates">
                    SS Notification Templates
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>


            <div fxLayout="row" class="p-12">
                <div class="w-240">
                    <button class="w-100-p" (click)="rightPanelView='settings'" mat-raised-button [color]="(rightPanelView === 'settings') ? 'primary' : ''" >
                        Settings
                    </button>
                    <button class="w-100-p mt-8" (click)="rightPanelView='preview'" mat-raised-button [color]="(rightPanelView === 'preview') ? 'primary' : ''" >
                        Preview
                    </button>
                    <button class="w-100-p mt-8" *ngIf="templateData.type === 'gridTemplate'" (click)="rightPanelView='gridTemplate'" mat-raised-button [color]="(rightPanelView === 'gridTemplate') ? 'primary' : ''" >
                        Grid Template
                    </button>
                    <button class="w-100-p mt-8" *ngIf="templateData.type === 'gridTemplate'" (click)="rightPanelView='gridReportSettings'" mat-raised-button [color]="(rightPanelView === 'gridReportSettings') ? 'primary' : ''" >
                        Grid Report Settings
                    </button>
                    <mat-accordion>
                        <ng-container *ngFor="let section of sections; let i=index">
                            <mat-expansion-panel *ngIf="!((templateData.type==='exPageTemplate' || templateData.type==='gridTemplate' || templateData.type==='gridReportSettings') && (section.key==='header' || section.key==='footer'))" (opened)="rightPanelView=section.key;changeRightPanelView()" [expanded]="i===0 && rightPanelView!='settings' && rightPanelView!='preview' && rightPanelView!='gridTemplate' && rightPanelView!='gridReportSettings'">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  {{ section.title }}
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                             <div fxLayout="column" *ngIf="templateData[rightPanelView]">
                                <ng-container *ngIf="!templateData.isMultiLang">
                                    <li class="ml-8"  [style.background]="(true) ? 'lightgray' : '' ">
                                        <a  style="cursor: pointer;">
                                            Content
                                        </a>
                                     </li>
                                </ng-container>
                                 <ng-container *ngIf="templateData.isMultiLang">
                                     <ng-container *ngFor="let item of languages">
                                             <li class="ml-8" *ngIf="templateData[rightPanelView] && templateData[rightPanelView].selectedLangs && templateData[rightPanelView].selectedLangs.includes(item.id)" (click)="rightPanelView=section.key;rightPanelLang=item.id;changeRightPanelView()" [style.background]="(rightPanelView===section.key && rightPanelLang===item.id) ? 'lightgray' : '' ">
                                                <a  style="cursor: pointer;">
                                                    {{ item.title }}
                                                </a>
                                             </li>
                                    </ng-container>
                                 </ng-container>
                                <br>
                             </div>
                            </mat-expansion-panel>
                        </ng-container>

                      </mat-accordion>
                </div>
                <div class="ml-20"  fxFlex>
                    <ng-container *ngIf="rightPanelView=='preview'" >
                        <div>
                            <div class="p-12" *ngIf="templateData.isMultiLang">
                                <div fxLayout="row" >
                                    <div>
                                        <button class="mr-8" [color]="(htmlPreviewLang===item.id) ? 'primary' : ''" mat-raised-button (click)="htmlPreviewLang=item.id" *ngFor="let item of languages" > {{ item.title }} </button>
                                    </div>
                                    <span fxFlex></span>
                                    <div>
                                        <button class="mr-8" mat-raised-button (click)="previewView='pdf'" [color]="(previewView==='pdf') ? 'primary' : ''" >Pdf</button>
                                        <button mat-raised-button (click)="previewView='html'" [color]="(previewView==='html') ? 'primary' : ''" >Html</button>
                                    </div>

                                </div>
                                <div *ngIf="previewView==='pdf'">
                                    <div class="p-12">
                                        <button mat-raised-button (click)="updatePDF()">
                                            Update
                                        </button>
                                        <div *ngIf="pdfURL">
                                            <iframe [src]="pdfURL" frameborder="0" style="height: 100vh; width: 100%;"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="previewView==='html'">
                                    <h2>Html preview</h2>
                                    <div style="border: 1px solid #c5c3c3c2; width: 90%; margin: auto; padding: 4px 24px; box-shadow: 10px 10px 5px #c5c4c475;">
                                        <div *ngIf="templateData.header && templateData.header.translation" [innerHTML]="(templateData.header.translation[htmlPreviewLang] || '') | htmlWithStyle" ></div>
                                        <div *ngIf="templateData.mainContent && templateData.mainContent.translation" [innerHTML]="(templateData.mainContent.translation[htmlPreviewLang] || '') | htmlWithStyle" ></div>
                                        <div *ngIf="templateData.footer && templateData.footer.translation" [innerHTML]="(templateData.footer.translation[htmlPreviewLang] || '') | htmlWithStyle" ></div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!templateData.isMultiLang">
                                <h2>Html preview</h2>
                                <div style="border: 1px solid #c5c3c3c2; width: 90%; margin: auto; padding: 4px 24px; box-shadow: 10px 10px 5px #c5c4c475;">
                                    <div [innerHTML]="templateData.header.content | htmlWithStyle" ></div>
                                    <div [innerHTML]="templateData.mainContent.content | htmlWithStyle" ></div>
                                    <div [innerHTML]="templateData.footer.content | htmlWithStyle" ></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="rightPanelView=='settings'" >
                        <div>
                            <mat-accordion>
                                <mat-expansion-panel [expanded]="true" hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                          General Settings
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                                      <div fxLayout="column">
                                          <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                                            <mat-form-field floatLabel="always" appearance="outline" fxFlex="49" class="happ-small-form-field-outline">
                                                <input matInput [(ngModel)]="templateData.title" placeholder="Template Name">
                                            </mat-form-field>
                                    
                                            <mat-form-field floatLabel="always" appearance="outline" fxFlex="49" class="happ-small-form-field-outline">
                                                <input matInput [(ngModel)]="templateData.category" placeholder="Template Category">
                                            </mat-form-field>
                                    
                                            <mat-form-field floatLabel="always" appearance="outline" fxFlex="49" class="happ-small-form-field-outline">
                                                <input matInput [(ngModel)]="templateData.shortSymbol" fxFlex="49" placeholder="Template Short Symbol">
                                            </mat-form-field>
                                            <mat-form-field  floatLabel="always" appearance="outline" class="happ-small-form-field-outline w-200" >
                                                <mat-label>Template Type</mat-label>
                                                <mat-select (selectionChange)="typeChanged()" [(ngModel)]="templateData.type" >
                                                    <mat-option value="recordTemplate">
                                                        Record Template
                                                    </mat-option>
                                                    <mat-option value="gridTemplate">
                                                        One Row Grid Report Template
                                                    </mat-option>
                                                    <mat-option value="ssNotificationTemplates">
                                                        SS Notification Templates
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                          </div>
                                          <div class="p-24" fxFlex>
                                            <div fxLayout="row" fxLayoutAlign="space-around center">
                                                <div fxFlex="49">
                                                    <div fxLayout="row" *ngIf="(templateData && templateData._id)">
                                                        <h3>
                                                            {{ (templateData && templateData._id) || '' }}
                                                        </h3>
                                                    </div>
                                                    <div>
                                                        <mat-form-field class="w-300">
                                                            <mat-label>Type of generator to use</mat-label>
                                                            <mat-select [(ngModel)]="templateData.typeOfGenerator">
                                                              <mat-option value="puppeter">
                                                                    New (Puppeter)
                                                              </mat-option>
                                                              <mat-option value="wkhtml">
                                                                    Old (Wkhtml)
                                                              </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div fxFlex="49">
                                                    <mat-checkbox [(ngModel)]="templateData.isMultiLang" >
                                                        Is Multiple Language
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div *ngIf="(templateData.typeOfGenerator === 'puppeter')">
                                                <ul>
                                                    <li>
                                                        When using pupetter the header max margin is 30mm
                                                    </li>
                                                    <li>
                                                        When using pupetter, to hide header and footer both "Use header" and "Use Footer" need to be disabled
                                                    </li>
                                                </ul>
                                            </div>
                                            <div fxLayout="row">
                                                <div fxFlex="90">
                                                    <mat-checkbox [(ngModel)]="templateData.showAsMainDocument">Set Document as main document on creation</mat-checkbox>
                                                </div>
                                                <div fxFlex="45">
                                                    <!-- <mat-checkbox [(ngModel)]="templateData.footer.show">Use the footer</mat-checkbox> -->
                                                </div>
                                            </div>
                                            <div fxLayout="row">
                                                <div fxFlex="45">
                                                    <mat-checkbox [(ngModel)]="templateData.header.show">Use the header</mat-checkbox>
                                                </div>
                                                <div fxFlex="45">
                                                    <mat-checkbox [(ngModel)]="templateData.footer.show">Use the footer</mat-checkbox>
                                                </div>
                                            </div>
                                            <div fxLayout="row" *ngIf="templateData.printringVarialbles && templateData.printringVarialbles.line">
                                                <div fxFlex="45">
                                                    <mat-checkbox [(ngModel)]="templateData.printringVarialbles.line.header">Use line in header</mat-checkbox>
                                                </div>
                                                <div fxFlex="45">
                                                    <mat-checkbox [(ngModel)]="templateData.printringVarialbles.line.footer">Use line in footer</mat-checkbox>
                                                </div>
                                            </div>
                                            <div fxLayout="row" *ngIf="templateData.printringVarialbles && templateData.printringVarialbles.margin">
                                                <div fxFlex="45">
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput [(ngModel)]="templateData.printringVarialbles.margin.top" placeholder="Margin Top (mm)">
                                                    </mat-form-field>
                                                </div>
                                                <div fxFlex="45">
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput [(ngModel)]="templateData.printringVarialbles.margin.bottom" placeholder="Margin Bottom (mm)">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div fxLayout="row" *ngIf="templateData.printringVarialbles && templateData.printringVarialbles.spacing">
                                                <div fxFlex="45">
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput [(ngModel)]="templateData.printringVarialbles.spacing.header" placeholder="Spacing header (mm)">
                                                    </mat-form-field>
                                                </div>
                                                <div fxFlex="45">
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput [(ngModel)]="templateData.printringVarialbles.spacing.footer" placeholder="Spacing footer (mm)">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div fxLayout="row" >
                                                <div fxFlex="32" fxLayout="column">
                                                    <h2>Content </h2>
                                                    <mat-radio-group [(ngModel)]="templateData.mainContent.editorType">
                                                        <mat-radio-button class="mr-8" [value]="'ck_editor'">Ck editor</mat-radio-button>
                                                        <mat-radio-button class="mr-8" [value]="'code'">Code editor</mat-radio-button>
                                                      </mat-radio-group>
                                                      <div *ngIf="templateData.mainContent.editorType === 'ck_editor'">
                                                          <br>
                                                          <mat-divider></mat-divider>
                                                          <br>
                                                          <mat-radio-group (change)="onSubTypeChange($event, 'mainContent')" [(ngModel)]="templateData.mainContent.subType">
                                                              <mat-radio-button class="mr-8" [value]="'normal'">Basic</mat-radio-button>
                                                              <mat-radio-button class="mr-8" [value]="'admin'">Admin</mat-radio-button>
                                                              <mat-radio-button class="mr-8" [value]="'superAdmin'">Super Admin</mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                      <div *ngIf="templateData.mainContent.editorType === 'code'">
                                                          <br>
                                                          <mat-divider></mat-divider>
                                                          <br>
                                                          <p> Use Container class in content </p>
                                                          <mat-radio-group [(ngModel)]="templateData.mainContent.useContainerClass">
                                                              <mat-radio-button class="mr-8" [value]="'yes'">Yes</mat-radio-button>
                                                              <mat-radio-button class="mr-8" [value]="'no'">No</mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                </div>
                                                <div fxFlex="32" fxLayout="column">
                                                    <h2>Header</h2>
                                                    <mat-radio-group [(ngModel)]="templateData.header.editorType">
                                                        <mat-radio-button class="mr-8" [value]="'ck_editor'">Ck editor</mat-radio-button>
                                                        <mat-radio-button class="mr-8" [value]="'code'">Code editor</mat-radio-button>
                                                      </mat-radio-group>
                                                      <div *ngIf="templateData.header.editorType === 'ck_editor'">
                                                          <br>
                                                          <mat-divider></mat-divider>
                                                          <br>
                                                          <mat-radio-group (change)="onSubTypeChange($event, 'header')" [(ngModel)]="templateData.header.subType">
                                                              <mat-radio-button class="mr-8" [value]="'normal'">Basic</mat-radio-button>
                                                              <mat-radio-button class="mr-8" [value]="'admin'">Admin</mat-radio-button>
                                                              <mat-radio-button class="mr-8" [value]="'superAdmin'">Super Admin</mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                </div>
                                                <div fxFlex="32" fxLayout="column">
                                                    <h2>Footer</h2>
                                                    <mat-radio-group [(ngModel)]="templateData.footer.editorType">
                                                        <mat-radio-button class="mr-8" [value]="'ck_editor'">Ck editor</mat-radio-button>
                                                        <mat-radio-button class="mr-8" [value]="'code'">Code editor</mat-radio-button>
                                                      </mat-radio-group>
                                                      <div *ngIf="templateData.footer.editorType === 'ck_editor'">
                                                          <br>
                                                          <mat-divider></mat-divider>
                                                          <br>
                                                          <mat-radio-group (change)="onSubTypeChange($event, 'footer')" [(ngModel)]="templateData.footer.subType">
                                                              <mat-radio-button class="mr-8" [value]="'normal'">Basic</mat-radio-button>
                                                              <mat-radio-button class="mr-8" [value]="'admin'">Admin</mat-radio-button>
                                                              <mat-radio-button class="mr-8" [value]="'superAdmin'">Super Admin</mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                </mat-expansion-panel>
                              
                                
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Header Settings
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                                    <div class="ml-12">
                                        <p >
                                            <strong>
                                                Header layout
                                            </strong>
                                        </p>
                                        <hr class="mb-12">
                                        <mat-radio-group fxLayout="row" fxLayoutAlign="space-around start" [(ngModel)]="templateData.header.headerTemplate" aria-label="Select an option">
                                            <mat-radio-button class="mb-4" [value]="'leftLogo'">Left side</mat-radio-button>
                                            <mat-radio-button class="mb-4" [value]="'rightLogo'">Right side</mat-radio-button>
                                            <mat-radio-button class="mb-4" [value]="'custom'">Custom</mat-radio-button>
                                          </mat-radio-group>

                                          <div style="text-align: center;" class="mt-24">
                                            <div [ngSwitch]="templateData.header.headerTemplate">
                                                <p *ngSwitchCase="'leftLogo'">
                                                   Selected Layout: Left side logo and right side slogan
                                                </p>
                                                <p *ngSwitchCase="'rightLogo'">
                                                    Selected Layout: Right side logo and left side slogan
                                                </p>
                                                <p *ngSwitchCase="'custom'">
                                                    Selected Layout: Custom Html
                                                </p>
                                            </div>
                                        </div>

                                          <p class="mt-12">
                                              <strong>Header Slogan</strong>
                                          </p>
                                          <hr class="mb-12">
                                          <mat-form-field style="border-bottom: 3px solid darkblue;" floatLabel="always" appearance="outline" class="happ-small-form-field-outline w-100-p" >
                                            <mat-label>Slogan</mat-label>
                                            <input matInput placeholder="" [(ngModel)]="templateData.header.slogan">
                                        </mat-form-field>
                                        <p class="mt-12" style="text-align: center;">
                                            you can use var ##headerSlogan## in custom template
                                        </p>
                                    </div>
                                </mat-expansion-panel>
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                          Footer Settings
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                                    <div>
                                        <p >
                                            <strong>
                                                Footer layout
                                            </strong>
                                        </p>
                                        <hr class="mb-12">
                                        <mat-radio-group fxLayout="column" [(ngModel)]="templateData.footer.footerTemplate" aria-label="Select an option">
                                            <mat-radio-button class="mb-4" [value]="'leftDate'">Left side date and right side page</mat-radio-button>
                                            <mat-radio-button class="mb-4" [value]="'rightDate'">left side slogan and right side page</mat-radio-button>
                                            <mat-radio-button class="mb-4" [value]="'custom'">Custom</mat-radio-button>
                                          </mat-radio-group>
                                    </div>
                                    <p class="mt-12">
                                        <strong>Footer Slogan</strong>
                                    </p>
                                        <hr class="mb-12">
                                        <mat-form-field style="border-bottom: 3px solid darkblue;" floatLabel="always" appearance="outline" class="happ-small-form-field-outline w-100-p" >
                                        <mat-label>Slogan</mat-label>
                                        <input matInput placeholder="" [(ngModel)]="templateData.footer.slogan">
                                    </mat-form-field>
                                    <p class="mt-12" style="text-align: center;">
                                        you can use var ##footerSlogan## in custom template
                                    </p>
                                    <p class="mt-12">
                                        <strong>Footer Page template</strong>
                                    </p>
                                        <hr class="mb-12">
                                        <mat-form-field style="border-bottom: 3px solid darkblue;" floatLabel="always" appearance="outline" class="happ-small-form-field-outline w-100-p" >
                                        <mat-label>Footer Page template</mat-label>
                                        <input matInput placeholder="" [(ngModel)]="templateData.footer.pageTemplateVar">
                                    </mat-form-field>
                                </mat-expansion-panel>
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                          Logo Settings
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                                    <div>
                                        <p class="mt-12">
                                            <strong>Upload logo</strong>
                                        </p>
                                            <hr class="mb-12">
                                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                                <div class="file-upload">
                                                    <label for="upload" class="file-upload__label">{{ 'popup.upload_file' | translate }}</label>
                                                    <input id="upload" class="file-upload__input" type="file" #file (change)="uploadFile($event, file)" />
                                                  </div>
                                                  <span fxFlex></span>
                                                  <div *ngIf="templateData.logoBase64">
                                                    <img [src]="templateData.logoBase64" style="width: 150px;" alt="">
                                                  </div>
                                            </div>
                                          <p class="mt-12">
                                            <strong>Set resolution</strong>
                                        </p>
                                        <hr class="mb-12">
                                        <div fxLayout="row" fxLayoutAlign="space-around start">
                                            <mat-form-field style="border-bottom: 3px solid darkblue;" floatLabel="always" appearance="outline" class="mr-8 happ-small-form-field-outline w-100-p" >
                                                <mat-label>Logo Width</mat-label>
                                                <input matInput placeholder="" [(ngModel)]="templateData.logoWidth">
                                            </mat-form-field>
                                            <mat-form-field style="border-bottom: 3px solid darkblue;" floatLabel="always" appearance="outline" class="mr-8 happ-small-form-field-outline w-100-p" >
                                                <mat-label>Logo Height</mat-label>
                                                <input matInput placeholder="" [(ngModel)]="templateData.logoHeight">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                      <mat-panel-title>
                                        Language Settings
                                      </mat-panel-title>
                                      <mat-panel-description>
                                      </mat-panel-description>
                                    </mat-expansion-panel-header>
                                   <div>
                                      <h2> Available languages for this template </h2>
                                      <table>
                                          <tbody>
                                              <tr>
                                                  <td>Language</td>
                                                  <td>Content</td>
                                                  <td>Header</td>
                                                  <td>Footer</td>
                                              </tr>
                                              <tr *ngFor="let item of languages">
                                                  <td>
                                                      {{ item.title }}
                                                  </td>
                                                  <td>
                                                      <mat-checkbox [disabled]="item.id === 'en'" [checked]="templateData.mainContent.selectedLangs.includes(item.id)" (change)="onCheckBoxChange(item.id, 'mainContent')" ></mat-checkbox>
                                                  </td>
                                                  <td>
                                                      <mat-checkbox [disabled]="item.id === 'en'" [checked]="templateData.header.selectedLangs.includes(item.id)" (change)="onCheckBoxChange(item.id, 'header')" ></mat-checkbox>
                                                  </td>
                                                  <td>
                                                      <mat-checkbox [disabled]="item.id === 'en'" [checked]="templateData.footer.selectedLangs.includes(item.id)" (change)="onCheckBoxChange(item.id, 'footer')" ></mat-checkbox>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <p class="mt-12"> IMP: If any lagnuage not availbale in platform, we will load default en </p>
                                   </div>
                                  </mat-expansion-panel>
                              </mat-accordion>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="templateData[rightPanelView]">
                        <ng-container *ngIf="templateData.isMultiLang">
                            <ng-container *ngIf="templateData[rightPanelView].editorType === 'ck_editor'" >
                                <ck-editor [config]="config[rightPanelView]" name="editor1" [(ngModel)]="templateData[rightPanelView].translation[rightPanelLang]" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                            </ng-container>
                            <ng-container *ngIf="templateData[rightPanelView].editorType === 'code'" >
                                <mat-form-field class="example-full-width w-100-p" appearance="fill">
                                    <mat-label>Code</mat-label>
                                    <textarea rows="18" style="width: 100%;" matInput [(ngModel)]="templateData[rightPanelView].translation[rightPanelLang]"></textarea>
                                  </mat-form-field>
                                <!-- <ngs-code-editor (valueChanged)="onCodeChanged($event, rightPanelView, rightPanelLang)" style="height: 100%" theme="vs-dark" [value]="currentValue"   language="html" [options]="options"></ngs-code-editor> -->
                                <!-- <ngs-code-editor style="height: 100%" theme="vs-dark" [(value)]="templateData[rightPanelView].translation[rightPanelLang]"  language="html" [options]="options"></ngs-code-editor> -->

                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!templateData.isMultiLang">
                            <ng-container *ngIf="templateData[rightPanelView].editorType === 'ck_editor'" >
                                <ck-editor [config]="config[rightPanelView]" name="editor1" [(ngModel)]="templateData[rightPanelView].content" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                            </ng-container>
                            <ng-container *ngIf="templateData[rightPanelView].editorType === 'code'" >
                                <mat-form-field class="example-full-width w-100-p" appearance="fill">
                                    <mat-label>Code</mat-label>
                                    <textarea rows="18" style="width: 100%;" matInput [(ngModel)]="templateData[rightPanelView].content"></textarea>
                                  </mat-form-field>
                                <!-- <ngs-code-editor (valueChanged)="onContentChange($event, rightPanelView)" style="height: 100%" theme="vs-dark" [value]="currentValue"  language="html" [options]="options"></ngs-code-editor> -->
                            </ng-container>
                        </ng-container>
                        <div class="mt-40">
                            <mat-accordion>
                                <mat-expansion-panel hideToggle>
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Global Variables
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                  </mat-expansion-panel-header>
                                  <div>
                                    <div class="p-12" *ngIf="globalVars">
                                        <div fxLayout="row">
                                            <h2> Global Variables </h2>
                                            <span fxFlex></span>
                                            <h2>
                                                Selected: {{ (templateData.typeOfGenerator === 'puppeter') ? 'Pupetter' : 'Wkhtml' }}
                                            </h2>
                                        </div>
                                        <div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Field</td>
                                                        <td style="text-align: center">Wkhtml</td>
                                                        <td style="text-align: center">Puppeter</td>
                                                    </tr>
                                                    <tr *ngFor="let item of fieldVars">
                                                        <td>{{ item.key }}</td>
                                                        <td style="text-align: center">
                                                            <mat-checkbox [disabled]="true" [checked]="item.wkhtml" ></mat-checkbox>
                                                        </td>
                                                        <td style="text-align: center">
                                                            <mat-checkbox [disabled]="true" [checked]="item.pupetter" ></mat-checkbox>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                      
                                        <hr>
                                    </div>
                                    <div class="p-12">
                                        <h2>Form Variables</h2>
                                        <hr>
                                    </div>
                                    <div class="p-12">
                                        <h2>Custom Variable</h2>
                                        <hr>
                                    </div>
                                </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                        </div>
                       
                    </ng-container>
                    <ng-container *ngIf="rightPanelView=='gridReportSettings'" >
                        <div fxLayout="column" class="mt-8" >
                            <h2>Enable Aggregate Sum on Report</h2>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <p>Enable Aggregation</p>
                                <mat-checkbox class="ml-4" [(ngModel)]="templateData.enableAggregate" (change)="onEnableAggregateChange()" ></mat-checkbox>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" class="mt-8 mb-8">
                                <p>Enable Count, result saved under count</p>
                                <mat-checkbox class="ml-4" [(ngModel)]="templateData.aggregateConfig.countEnabled" ></mat-checkbox>
                            </div>
                            <div fxLayout="row" *ngIf="templateData.enableAggregate" class="mt-16">
                                <mat-form-field floatLabel="always" appearance="outline" class="happ-small-form-field-outline w-100-p mr-8" >
                                    <mat-label>Aggregate Field</mat-label>
                                    <input matInput placeholder="" [(ngModel)]="templateData.aggregateConfig.groupBy">
                                    <mat-hint class="mt-20">Enter Field to group by before counting</mat-hint>
                                </mat-form-field>

                                <mat-form-field floatLabel="always" appearance="outline" class="happ-small-form-field-outline w-100-p mr-8" >
                                    <mat-label>Sum By Field 1</mat-label>
                                    <input matInput placeholder="" [(ngModel)]="templateData.aggregateConfig.sumField1">
                                    <mat-hint class="mt-20">Enter Field to sum after group by, result saved under sum1</mat-hint>
                                </mat-form-field>
                                <mat-form-field floatLabel="always" appearance="outline" class="happ-small-form-field-outline w-100-p mr-8" >
                                    <mat-label>Sum By Field 2</mat-label>
                                    <input matInput placeholder="" [(ngModel)]="templateData.aggregateConfig.sumField2">
                                    <mat-hint class="mt-20">Enter Field 2 to sum after group by, result saved under sum2</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="rightPanelView=='gridTemplate'" >
                        <div class="p-12">
                            <div fxLayout="row">
                                <h2>Grid Column Template</h2>
                                <span fxFlex></span>
                                <div style="margin: auto;">
                                    <button mat-raised-button (click)="addColumn()" >Add Column</button>
                                </div>
                            </div>
                            <div *ngFor="let col of templateData.gridColumns; let i=index" fxLayout="column" class="mb-12" style="border: 1px solid #c7bfbf; border-radius: 8px;">
                                <div fxLayout="row" class="ml-8 mb-12">
                                    <button mat-icon-button (click)="removeColumn(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <h3 style="margin: auto 0px; font-weight: 700;">
                                        Column: {{ col.heading }}
                                    </h3>
                                </div>
                                <div fxLayoutAlign="space-around center" fxLayout="row">
                                    <mat-form-field fxFlex="48" floatLabel="always" appearance="outline" class="happ-small-form-field-outline" >
                                        <mat-label>Width</mat-label>
                                        <input matInput [(ngModel)]="col.width" placeholder="Width">
                                    </mat-form-field>
                    
                                    <mat-form-field floatLabel="always" appearance="outline" class="happ-small-form-field-outline" fxFlex="48">
                                        <mat-label>Heading</mat-label>
                                        <input matInput [(ngModel)]="col.heading" floatLabel="always" appearance="outline" class="happ-small-form-field-outline" placeholder="Heading">
                                    </mat-form-field>
                                </div>
                                <div class="ml-16 mb-16">
                                    <p class="mb-0">{{col.align}}</p>
                                    <mat-radio-group [(ngModel)]="col.align">
                                        <mat-radio-button class="m-4" value="LEFT">
                                            Left
                                        </mat-radio-button>
                                        <mat-radio-button class="m-4" value="CENTER">
                                            Center
                                        </mat-radio-button>
                                        <mat-radio-button class="m-4" value="RIGHT">
                                            Right
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ck-editor name="editor1" [(ngModel)]="col.variables" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                    
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

</div>