<div>
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <button (click)="close()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
      <span class="title dialog-title"> {{ 'workspace.create_new_card_action' | translate }} </span>
      <span fxFlex></span>
      <button (click)="save()" mat-icon-button>
        <mat-icon>done</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div class="p-16" fxLayout="column">
    <app-setting-item-view heading="workspace.action_name" description="workspace.action_name_desc">
      <mat-form-field class="no-padding" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
        <input [(ngModel)]="cardAction.name" matInput placeholder="">
      </mat-form-field>
    </app-setting-item-view>
    <app-setting-item-view heading="workspace.key_name" description="workspace.key_name_desc">
      <mat-form-field class="no-padding" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
        <input [(ngModel)]="cardAction.keyName" matInput placeholder="">
      </mat-form-field>
    </app-setting-item-view>
    <app-setting-item-view heading="workspace.is_active" description="workspace.is_active_desc">
      <div class="w-200">
        <mat-checkbox  [(ngModel)]="cardAction.isActive" > </mat-checkbox>
      </div>
    </app-setting-item-view>
    <app-setting-item-view heading="workspace.show_on_search" description="workspace.show_on_search_desc">
      <div class="w-200">
        <mat-checkbox  [(ngModel)]="cardAction.showSearch" > </mat-checkbox>
      </div>
    </app-setting-item-view>
    <app-setting-item-view *ngIf="cardAction.showSearch" heading="workspace.search_field_type" description="workspace.search_field_type_desc">
      <mat-form-field class="no-padding" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
        <mat-select [(ngModel)]="cardAction.searchType">
          <mat-option value="input"> {{ 'workspace.input' | translate }} </mat-option>
          <mat-option value="dropdown"> {{ 'workspace.dropdown' | translate }} </mat-option>
        </mat-select>
      </mat-form-field>
    </app-setting-item-view>
    <app-setting-item-view heading="workspace.show_action" description="workspace.show_action_desc">
      <div class="w-200">
        <mat-checkbox  [(ngModel)]="cardAction.showAction" > </mat-checkbox>
      </div>
    </app-setting-item-view>
    <app-setting-item-view *ngIf="cardAction.showAction" heading="workspace.action_type" description="workspace.action_type_desc">
      <mat-form-field class="no-padding" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
        <mat-select [(ngModel)]="cardAction.actionType">
          <mat-option value="dropdown"> {{ 'workspace.dropdown' | translate }} </mat-option>
          <mat-option value="horizontalButtons"> {{ 'workspace.horizontal_buttons' | translate }} </mat-option>
          <mat-option value="nextActionButtons"> {{ 'workspace.next_action_buttons' | translate }} </mat-option>
          
          <mat-option value="input"> {{ 'workspace.input' | translate }} </mat-option>
          <mat-option value="input-number"> {{ 'workspace.input_number' | translate }} </mat-option>
          <mat-option value="editor"> {{ 'workspace.editor' | translate }} </mat-option>
          <mat-option value="date"> {{ 'workspace.date' | translate }} </mat-option>
          <mat-option value="link"> {{ 'workspace.link' | translate }} </mat-option>
        </mat-select>
      </mat-form-field>
    </app-setting-item-view>
    <app-setting-item-view *ngIf="cardAction.actionType === 'input' || cardAction.actionType === 'dropdown'" heading="workspace.field_disabled" description="workspace.field_disabled_desc">
      <div class="w-200">
        <mat-checkbox  [(ngModel)]="cardAction.disabled" > </mat-checkbox>
      </div>
    </app-setting-item-view>
    <app-setting-item-view *ngIf="cardAction.actionType === 'horizontalButtons' || cardAction.actionType === 'nextActionButtons'" heading="workspace.showButtonLabel" description="workspace.showButtonLabel_desc">
      <div class="w-200">
        <mat-checkbox  [(ngModel)]="cardAction.showLabel" > </mat-checkbox>
      </div>
    </app-setting-item-view>
    <app-setting-item-view heading="workspace.use_for_sort" description="workspace.use_for_sort_desc">
      <div class="w-200">
        <mat-checkbox  [(ngModel)]="cardAction.useForSort" > </mat-checkbox>
      </div>
    </app-setting-item-view>
    <app-setting-item-view heading="workspace.sort" description="workspace.sort_desc">
      <mat-form-field class="no-padding" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
        <input [(ngModel)]="cardAction.sort" type="number" matInput placeholder="">
      </mat-form-field>
    </app-setting-item-view>
    <app-setting-item-view *ngIf="cardAction.actionType === 'nextActionButtons'" heading="workspace.next_action_class" description="You can use classed like light-green-300-bg primary-300-fg">
      <mat-form-field class="no-padding" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
        <input [(ngModel)]="cardAction.actionClass" matInput placeholder="">
      </mat-form-field>
    </app-setting-item-view>
    <app-setting-item-view heading="workspace.width" description="workspace.width_desc">
      <mat-form-field class="no-padding" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
        <input [(ngModel)]="cardAction.flexWidth" type="number" matInput placeholder="">
      </mat-form-field>
    </app-setting-item-view>

    <mat-divider></mat-divider>
    <br>
    <div fxLayout="column">
      <div fxLayout="row wrap">
        <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field floatLabel="never" floatLabel="always" appearance="outline" fxFlex="32">
            <mat-label> {{ 'workspace.option_name' | translate }} </mat-label>
            <input type="text" autofocus="true" matInput [(ngModel)]="currentValue.name">
          </mat-form-field>
          <mat-form-field floatLabel="never" floatLabel="always" appearance="outline" fxFlex="32">
            <mat-label> {{ 'workspace.option_value' | translate }} </mat-label>
            <input type="text" autofocus="true" matInput [(ngModel)]="currentValue.value">
          </mat-form-field>
          <mat-form-field floatLabel="never" floatLabel="always" appearance="outline" fxFlex="32">
            <mat-label> {{ 'workspace.option_sort' | translate }} </mat-label>
            <input type="number" autofocus="true" matInput [(ngModel)]="currentValue.sort">
          </mat-form-field>
          <button mat-icon-button class="mb-20" (click)="saveOption()">
            <mat-icon>done</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="row wrap" style="border: 1px solid #bbb5b5;" class="w-100-p mb-4" *ngFor="let option of cardAction.options; let i=index">
        <button mat-icon-button (click)="deleteOption(i)">
          <mat-icon>delete</mat-icon>
        </button>
        <div class="w-90-p" fxLayout="row"  fxLayoutAlign="space-around center">
          <p>{{ 'workspace.option_name' | translate }}: {{ option.name }}</p>
          <p>{{ 'workspace.option_value' | translate }}: {{ option.value }}</p>
          <p>{{ 'workspace.option_sort' | translate }}: {{ option.sort }}</p>
        </div>
      </div>
    </div>
  </div>
</div>