import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class ArraySortPipe  implements PipeTransform {
  transform(array: any[], field: string, order: 'asc' | 'desc' = 'desc', type: 'number' | 'string' = 'string'): any[] {
    if (!array || array && array.length === 0) {
    return;
    }
    const orderVal = (order === 'asc') ? 1 : -1;
    array.sort((a: any, b: any) => {
      const tempA = (type === 'number' && a[field]) ? parseFloat(a[field]) : (a[field] || '');
      const tempB = (type === 'number' && b[field]) ? parseFloat(b[field]) : (b[field] || '');
      if (tempA < tempB) {
        return orderVal;
      } else if (tempA > tempB) {
        return (orderVal * -1);
      } else {
        return 0;
      }
    });
    return array;
  }
}
