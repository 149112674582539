import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { DatatableService } from '../../../datatable.service';
var CardActionsComponent = /** @class */ (function () {
    function CardActionsComponent(_helperService, translationService, datatableService, _changeDetectorRef, _adapter) {
        this._helperService = _helperService;
        this.translationService = translationService;
        this.datatableService = datatableService;
        this._changeDetectorRef = _changeDetectorRef;
        this._adapter = _adapter;
        this.rowData = {};
        this.optionClicked = new EventEmitter();
        this.isEdit = false;
        this.config = {
            height: 150,
            toolbar: [
                ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule']
            ]
        };
        this.oldValue = {};
    }
    CardActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cardActions = this.cardActions || [];
        this._adapter.setLocale(this.translationService.getDefaultLang());
        this.cardActions = this.cardActions.filter(function (ele) { return (ele && ele.isActive); });
        this.oldValue = JSON.parse(JSON.stringify(this.rowData));
        this.cardActions.forEach(function (action) {
            if (action && action.actionType === 'editor') {
                _this.rowData[_this.rowData['_id'] + "_" + action.keyName] = _this.checkForEditMode(action);
            }
        });
    };
    CardActionsComponent.prototype.openLink = function (link) {
        window.open(link, '_blank', 'location=yes,height="100%",width="100%",top="10px",left="10px",scrollbars=yes,status=yes');
    };
    CardActionsComponent.prototype.checkForEditMode = function (action) {
        if (action && this.rowData[action.keyName] && action.actionType === 'editor') {
            return false;
        }
        return true;
    };
    CardActionsComponent.prototype.optionChanged = function (option, action) {
        option.keyName = action.keyName;
        this.optionClicked.emit(option);
        option.recId = this.rowData._id;
        this.datatableService.onCardViewChange.next(option);
        if (!this._changeDetectorRef['destroyed']) {
            this._changeDetectorRef.detectChanges();
        }
    };
    CardActionsComponent.prototype.nextActionClicked = function (action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tempResp, extraData, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.rowData.processStatusId) return [3 /*break*/, 5];
                        this.optionChanged({ value: "loading", doNotUpdate: true }, action);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._helperService.getRequest("api/reg-process/" + this.rowData['_id'] + "/nextDefaultAction?currentAct=" + this.rowData.processStatusId)];
                    case 2:
                        tempResp = _a.sent();
                        extraData = {};
                        if (tempResp && tempResp.type === "info") {
                            this._helperService.showInfo("global." + tempResp.message, "Info", "info");
                            this.optionChanged({ value: "done", doNotUpdate: true }, action);
                        }
                        else {
                            extraData = tempResp;
                            this._helperService.showInfo("global.process_success", "Success", "success");
                            this.optionChanged({ value: "done", doNotUpdate: true, extraData: extraData }, action);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.optionChanged({ value: "done", doNotUpdate: true }, action);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this._helperService.showInfo("global.no_process_status_id_in_form", "Info", "info");
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CardActionsComponent.prototype.closeEditor = function (action) {
        this.rowData[this.rowData['_id'] + "_" + action.keyName] = this.checkForEditMode(action);
    };
    CardActionsComponent.prototype.editorChange = function (value, action) {
        this.rowData[this.rowData['_id'] + "_" + action.keyName] = this.checkForEditMode(action);
        this.optionChanged({ value: value }, action);
    };
    CardActionsComponent.prototype.inputChange = function (value, action) {
        this.oldValue[action.keyName] = this.oldValue[action.keyName] || '';
        if (value !== this.oldValue[action.keyName]) {
            this.oldValue[action.keyName] = value;
            this.optionChanged({ value: value }, action);
        }
    };
    return CardActionsComponent;
}());
export { CardActionsComponent };
