import { ConstActions } from './../../consts/positionActions.const';
import {
  Component, OnInit, Input, AfterViewInit, ViewChild, ViewEncapsulation, OnDestroy, Output, EventEmitter, 
  OnChanges, ChangeDetectorRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DatatableService } from './datatable.service';
import { Page } from './datatable.page.interface';
import { MatSnackBar, MatDialogRef, DateAdapter } from '@angular/material';
import { MatDialog } from '@angular/material';
import { DescriptionPopupComponent } from './description-popup/description-popup.component';
import { HelpersService } from '../../services/helpers/helpers.service';
import { PrivateFilterComponent } from './private-filter/private-filter.component';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import { WsAppsSettingsComponent } from '../../apps/ws-apps-settings/ws-apps-settings.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as _ from 'lodash';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { IOfficeData } from '../../interfaces/office.interface';
import { RecordBulkUpdateComponent } from './record-bulk-update/record-bulk-update.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DtTableReportPopupComponent } from '../../components/dt-table-report-popup/dt-table-report-popup.component';
import { WorkspaceQuery } from '../../state/workspaceState/workspace.query';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

declare var $: any;

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class DatatableComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() regId: string;
  temptmeptmep

  loadingIndicator = true;
  confirmDialogRef: MatDialogRef<InputDialogComponent>;
  public mask = [/[0-2]/, /[0-3]/, ':', /[0-5]/, /\d/];
  private unsubscribe = new Subject<void>();

  mqFilterString = ``;
  expandedRecordId;
  allData: any = {};
  requestData: any = {};
  timeFilter: any = {
    startTime: '00:00',
    endTime: '23:59'
  };
  dateTimeFilterType = 'custom';
  valueFilter: any = {};
  refreshOfficeFilter = 0;

  lastSearchPersisted = false;
  init = true;
  @ViewChild('privateFilter')
  public privateFilter: PrivateFilterComponent;
  @Input() options;
  @Input() isAdmin;
  @Input() hardFilter;
  @Input() fieldData;
  @Input() appData: any;
  @Input() wsInfo;
  @Input() calculateOnSave: boolean;
  @Output() updateOnSave: EventEmitter<any> = new EventEmitter();
  fastQuery = '';
  selected = [];
  loading = false;
  dataTablePastRequestData: any;
  public preview;
  public enableMoreActionIcon = false;
  public currentLang: string;
  public translationDatatable: object;
  public filters: object = {};
  public dataRanageKey: string;
  public columns: any = [];
  public page = new Page();
  public rows;
  public dataTablesParameters: object = {};

  defaultHardFilter;
  wsRequiredFieldDone = true;
  officeId: string;
  errorMessage = '';
  mqHardFilterString = '';
  currentMQFilter = '';
  open = false;
  inlineEditCols = [];
  inlineEditCell = {};
  cardVars = {};
  cardVarsArray = [];
  dtTableAdvanceFilters = [];
  chartData = [];
  rowCalculations = {};
  colCalculations = {};
  summaryArray = [];
  searchText = '';
  processFilters = {};
  showTotalRow = false;
  filterOption: any;
  workspaceData: any;
  items: any = {};
  officeTags: any;
  tagFilterInput: any = {};
  filterValue: any = {};
  addLoading: boolean;
  sheetList: any;
  refresh: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    public datatableService: DatatableService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private helperService: HelpersService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _officeHelper: OfficeHelpersService,
    private _fuseSidebarService: FuseSidebarService,
    private translationService: TranslateService,
    private _adapter: DateAdapter<any>,
    private workspaceQuery: WorkspaceQuery


  ) {

    this._adapter.setLocale(this.translationService.getDefaultLang());

    this.route.queryParams.subscribe((params) => {
    });

    this.initDateFilter();

    // translations
    this.helperService
      .getTrans('datatable')
      .then(data2 => {
        this.translationLoader.loadTranslationsV2(data2);
      })
      .catch(err => {
        console.info(err);
      });

    this.currentLang = this.translate.currentLang || 'en';

    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.currentLang = event['lang'] || this.translate.currentLang;
      },
      err => {
        console.info(err);
      }
    );

    this.datatableService.onCardViewChange.subscribe((data: any) => {
      if (data) {
        if (data.doNotUpdate && data.value) {
          console.log("All data change", data);
          this.cardOptionChanged({ optionData: data, recId: data.recId })

        }
      }
    });
  }

  ngOnChanges(changes: any) {
    this.fastQuery = '';
    this.summaryArray = [];
    this.requestData = {};
    this.getStoredFilterForView();
    // this.appData = {};
    this.dateTimeFilterType = (this.appData && this.appData.defaultDateFilter) || '##df_all##';
    this.valueFilter = {
      'registredDataTime': (this.appData && this.appData.defaultDateFilter) || '##df_all##'
    };
    if ((changes.regId && !changes.regId.firstChange) || (changes.appData && !changes.appData.firstChange)) {
      this.rows = null;
      this.init = true;
      this.requestData = {};
      this.initDateFilter();
      this.resetFilterValues();
      this.setFromLocalData();
      this.getServerSideRecord();
    }
  }

  ngOnInit(): void {
    this.processFilters = {};
    this.getOfficeTags();
    this.initDateFilter();
    this.setFromLocalData();
    this.getServerSideRecord();
    this.checkWSRequiredField();

    this.datatableService.onPositionRecordChange.subscribe((isChanged: boolean) => {
      if (this.datatableService.applicationType === 'position' && isChanged) {
        this.updateParentRecord();
      }
    });

    this.datatableService.reRenderData.subscribe((reRender: boolean) => {
      if (reRender && this.regId) {
        this.rows = null;
        this.checkWSRequiredField();
        this.getServerSideRecord();
      }
    });

    this.datatableService.onFilterChanged.subscribe((filters) => {
      if (filters && Object.keys(filters).length) {
        this.checkWSRequiredField();
        this.requestData['filters'] = filters || {};
        if (this.allData && Object.keys(this.allData).length) {
          this.getServerSideRecord();
        }
      }
    },
      err => {
        this.filters = this.filters || {};
        console.info(err);
        this.snackBar.open(
          err || 'Error occured while getting data',
          'Okay',
          {
            duration: 2000
          }
        );
      }
    );

    this.page.size = (this.allData && this.allData['length']) || 15;

    this.resetValues();

    this.formChartAndInlineEditVariable();

    this.workspaceQuery.workspaceDoc.pipe(takeUntil(this.unsubscribe)).subscribe((workspaceDoc) => {
      if (workspaceDoc) {
        this.workspaceData = JSON.parse(JSON.stringify(workspaceDoc));
      }
    });
  }

  setFromLocalData() {
    if (this.datatableService.applicationType !== 'position') {
      const tt = (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
      const appSlug = tt['appUrlSlug'] || tt['appSlug'];
      const temp = (localStorage.getItem(appSlug) && JSON.parse(localStorage.getItem(appSlug))) || {};
      this.dataTablePastRequestData = temp;
      if (temp && Object.keys(temp).length) {
        this.lastSearchPersisted = true;
        this.reopenFilters();
      } else {
        this.lastSearchPersisted = false;
      }
      if (temp && temp.valueFilter) {
        console.log("this.allData.filters.values 444", this.allData.filters.values);
        this.valueFilter = temp.valueFilter;
      }
      if (temp && temp.requestData && Object.keys(temp.requestData)) {
        delete temp.requestData.page;
        this.requestData = temp.requestData || {};
        this.requestData.filters = this.requestData.filters || {};
        this.dateTimeFilterType = (this.appData && this.appData.defaultDateFilter) || '##df_all##';
        this.requestData.filters = Object.assign(this.requestData.filters, {
          'registredDataTime': (this.appData && this.appData.defaultDateFilter) || '##df_all##'
        });
        console.log("this.allData.filters.values 555", this.allData.filters.values);
        this.valueFilter = this.requestData.filters;
      }
      this.timeFilter = temp.timeFilter || this.timeFilter;
      this.dateTimeFilterType = temp.dateTimeFilterType || this.dateTimeFilterType;
    }
  }

  setLocalData() {
    if (this.datatableService.applicationType !== 'position') {
      const tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
      const temp = {
        requestData: this.requestData,
        openedFilters: {
          advanceFilter: (this.allData && this.allData.filters && this.allData.filters.noAdvanceFiltersSelected),
          dateRangeFilter: (this.allData && this.allData.showDateRange),
          superFilter: (this.allData && this.allData.showSuperFilter),
        },
        dateTimeFilterType: this.dateTimeFilterType,
        valueFilter: this.valueFilter,
        timeFilter: this.timeFilter
      };

      const appSlug = tt['appUrlSlug'] || tt['appSlug'];
      localStorage.setItem(appSlug, JSON.stringify(temp));
    }
  }

  resetLocalData() {
    if (this.datatableService.applicationType !== 'position') {
      const tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
      const temp = {
        requestData: {},
        openedFilters: {
          advanceFilter: (this.allData && this.allData.filters && this.allData.filters.noAdvanceFiltersSelected),
          dateRangeFilter: (this.allData && this.allData.showDateRange),
          superFilter: (this.allData && this.allData.showSuperFilter),
        },
        dateTimeFilterType: ''
      };
      this.initDateFilter();
      const appSlug = tt['appUrlSlug'] || tt['appSlug'];
      localStorage.setItem(appSlug, JSON.stringify(temp));
    }
  }

  ngOnDestroy() {
    const tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
    delete tt['statusId'];
    delete tt['instanceId'];
    localStorage.setItem('wsAppData', JSON.stringify(tt));

    this.resetValues();
    this.rows = [];
    this.datatableService.instanceFilters = {};
    this.datatableService.onOptionChange['observers'] = [];
    this.datatableService.reRenderData['observers'] = [];
    this.datatableService.onFilterChanged['observers'] = [];
    this.datatableService.onPositionRecordChange['observers'] = [];

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  checkWSRequiredField() {
    this.wsRequiredFieldDone = true;
    if (
      this.appData &&
      this.appData.requiredDataFields &&
      this.appData.requiredDataFields.length
    ) {
      this.appData.requiredDataFields =
        this.appData.requiredDataFields || [];
      this.appData.requiredDataFields.forEach(ele => {
        if (ele.value === null || ele.value === undefined) {
          this.wsRequiredFieldDone = false;
        }
      });
    }
  }

  reInitColumnTotal() {
    if (this.allData && this.allData['columns']) {
      this.allData['columns'].forEach(element => {
        if (element && element['control_type'] && element['control_type']['allTotal'] && element['control_type']['control_type'] !== 'calculation_on_save') {
          this.colCalculations[element['control_type']['label']] = 0;
          this.showTotalRow = true;
        }
      });
    }
  }

  redirectCall(eve, functionName) {
    this[functionName](...eve);
  }

  onSort(event) {
    this.requestData['sort'] = (event.sorts && event.sorts[0]) || { dir: 'desc', prop: '_id' };
    this.allData['sort'] = event.sorts;
    this.getServerSideRecord();
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadingIndicator = true;
    this.getServerSideRecord();
  }

  getFilteredActions(actions, type) {
    actions = actions || [];
    actions = actions.filter(
      (action: any) =>
        action &&
        action.module &&
        (action.module === 'customRegisterRecordsList' ||
          action.module === 'addressbook')
    );
    actions = actions.filter(
      (action: any) =>
        action && action.placement && action.placement[type]
    );
    if (type === 'dropdownActions' && actions.length) {
      this.enableMoreActionIcon = true;
    }
    return actions;
  }

  makeReqBody(filters?, mqFilter?) {
    this.allData = this.allData || {};
    this.postGetData();

    this.requestData = this.requestData || {};

    if (!this.init) {
      this.requestData['page'] = this.page;
    }

    this.requestData['mqFilter'] = '';
    if (this.requestData['currentMQFilter'] && this.appData && this.appData['hardFilter']) {
      this.requestData['mqFilter'] = `${this.appData['hardFilter']} AND ${this.requestData['currentMQFilter']}`;
      // delete this.requestData['currentMQFilter'];
    } else if (this.requestData['currentMQFilter']) {
      this.requestData['mqFilter'] = this.requestData['currentMQFilter'];
      // delete this.requestData['currentMQFilter'];
    } else if (this.appData && this.appData['hardFilter']) {
      this.requestData['mqFilter'] = this.appData['hardFilter'];
    }

    if (mqFilter) {
      if (this.requestData['mqFilter']) {
        this.requestData['mqFilter'] = `${this.requestData['mqFilter']} AND ${mqFilter}`;
      } else {
        this.requestData['mqFilter'] = mqFilter;
      }
    }

    // in case of position request
    if (this.datatableService.params && this.datatableService.params['postionsLookupSettings'] ) {
      this.requestData['positionFilters'] = this.datatableService.params['postionsLookupSettings'] || {};
      const filterKey = (this.requestData['positionFilters'] && this.requestData['positionFilters']['field']) || 'parentId'
      this.requestData.mqFilter = this.requestData.mqFilter || '';
      if (typeof(this.datatableService.params.parentId) === "string") {
        if (this.requestData.mqFilter) {
          this.requestData.mqFilter = `${this.requestData.mqFilter} AND ${filterKey}='${this.datatableService.params.fieldValue}'`;
        } else {
          this.requestData.mqFilter = `${filterKey}='${this.datatableService.params.fieldValue}'`;
        }
      } else {
        this.requestData.filters = { ...this.requestData.filters, ...this.datatableService.params.filter }
      }
    }

    console.log("req filter", this.requestData.filter);
    this.requestData['search'] = {
      value: this.fastQuery || '',
      regex: false
    };

    if (this.datatableService && this.datatableService.applicationType === 'workspace') {
      this.appData = this.appData || this.datatableService.appData || {};
      const wsData = (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};

      this.requestData['workspace'] = {
        index: wsData.stepNumber || 0
      };
    }

    this.getFilters();

  }

  getServerSideRecord(filters?, mqFilter?) {
    this.makeReqBody(filters, mqFilter);
    if (this.regId) {
      this.setLocalData();
      this.loadingIndicator = true;

      // this.helperService.postRequest(`api/reg/${this.regId}/count-data-table`, this.requestData)
      //   .then((page: any) => {
      //     this.page = page;
      //   }).catch(err => {
      //     console.error(err);
      //   });
      if (this.datatableService.params && this.datatableService.params.formId) {
        this.requestData.formId = this.datatableService.params.formId;
      }
      this.helperService.postRequest(`api/reg/${this.regId}/data-table`, this.requestData)
        .then((data) => {

          this.sheetList = data.googleSheets || [];
          this.loadingIndicator = false;
          let tt = (data['dttable'] && data['dttable']['data']) || [];

          this.rowCalculations = data['rowCalculations'] || [];
          this.datatableService.integratedProcessId = data['integratedProcessId'];
          this.datatableService.formId = data['formId'];
          this.page = (data['dttable'] && data['dttable']['page']) || [];

          
          if (this.init) {
            this.allData['showDateRange'] = data.showDateRange;
            this.datatableService.allData = data || {};
            this.postGetData();
            this.allData['showDateRange'] = data.showDateRange;
            // this.requestData['currentMQFilter'] = data['currentMQFilter'] || '';
            this.init = false;


            if (this.datatableService.applicationType === 'position') {
              data['actions'] = JSON.parse(JSON.stringify(ConstActions));
              // hideDeleteButton
              if (this.fieldData && !this.fieldData.showDeleteButton) {
                // delete 
                data['actions'].splice(2, 1);
                // if price list reg selected.
                if (!(this.fieldData && this.fieldData.postionsLookupSettings && this.fieldData.postionsLookupSettings.price_list_regId)) {
                  data['actions'].splice(2, 1);
                }
              } else {
                // if price list reg selected.
                if (!(this.fieldData && this.fieldData.postionsLookupSettings && this.fieldData.postionsLookupSettings.price_list_regId)) {
                  data['actions'].splice(3, 1);
                }
              }
            }

            if (this.datatableService.applicationType === 'selection') {
              data['actions'].push({
                type: 'function',
                handler: (id) => {
                  // this.http.put()
                },
                translations: {
                  pl: 'dttable.select',
                  en: 'dttable.select'
                },
                default_label: 'dttable.select',
                placement: {
                  recordActions: true
                },
                module: 'customRegisterRecordsList'
              });
              data['actions'].push({
                'default_label': 'dttable.back',
                'translations': {
                  'pl': 'dttable.back',
                  'en': 'dttable.back'
                },
                'icon': '',
                'sort': '',
                'fuse2App': './../',
                'placement': {
                  'headerActions': true,
                  'subheaderActions': false,
                  'recordActions': false,
                  'dropdownActions': false
                },
                'module': 'customRegisterRecordsList',
                'application': 'app.customregistries.back',
                'params': {},
                'applicationName': 'Go Back',
                'access_level': '2',
                'actionParams': ''
              });
            }

            // now format the data...
            data['actions'] = this.datatableService.formatActions(data['actions'], data);
            this.allData = data;
            this.postGetData();
            this.allData['columns'] = (data && data['columns']) || [];
            if (this.appData && this.appData.showSubAction) {
              this.allData['columns'].push({
                'prop': 'subAction',
                'name': 'subAction',
                'isPullOnly': false
              });
            }
            this.allData['columnMode'] = 'flex';
            this.allData['headerHeight'] = 50;
            this.allData['footerHeight'] = 50;
            this.allData['rowHeight'] = 'auto';
            this.allData['externalPaging'] = true;
            this.defaultHardFilter = data['defaultFilter'] || null;

            if (this.datatableService && this.datatableService.applicationType !== 'workspace') {
              this.allData['columns'].push({
                prop: '_id',
                name: 'Actions',
                class: 'action-txt-align',
                sortable: false,
                'isPullOnly': false,
                render: this.getFilteredActions(data['actions'], 'recordActions').concat(this.getFilteredActions(data['actions'], 'dropdownActions'))
              });
            } else if (this.datatableService && this.datatableService.applicationType === 'workspace' && this.appData && !this.appData.hideActionColumn) {
              this.allData['columns'].push({
                prop: '_id',
                name: 'Actions',
                class: 'action-txt-align',
                sortable: false,
                'isPullOnly': false,
                render: this.getFilteredActions(data['actions'], 'recordActions').concat(this.getFilteredActions(data['actions'], 'dropdownActions'))
              });
            }
            this.allData['headerActions'] = this.getFilteredActions(data['actions'], 'headerActions') || [];
            this.allData['filters'] = data['filters'] || {};
            // show or hide filters....
            this.reopenFilters();
          }

          this.getStoredFilterForView();

          this.reInitColumnTotal();
          if (tt) {
            tt = tt.map((element, k) => {
              if (this.rowCalculations) {
                element = this.rowCalculateFun(element);
              }

              for (const i in element) {
                if (element.hasOwnProperty(i)) {
                  if (this.cardVars[i] && this.cardVars[i][element[i]]) {
                    this.cardVars[i][element[i]]['count'] += 1;
                  }

                  if (this.colCalculations[i] != null && this.colCalculations[i] !== undefined && element[i] &&
                    parseFloat(this.colCalculations[i]) !== NaN && parseFloat(element[i]) !== NaN
                  ) {
                    this.colCalculations[i] = parseFloat(this.colCalculations[i]) + parseFloat(element[i]);
                  }
                  if (this.inlineEditCols[i]) {
                    element[i] = this.inlineEditCols[i][element[i]];
                  }
                }
              }
              return element;
            });
          }


          this.rows = [...tt];
          this.reInitColumnTotal();

          this.rows = [...tt];
          this.getSum();
          this._changeDetectorRef.detectChanges();
        }).catch(err => {
          this.helperService.openSnackBar(err);
          this.loadingIndicator = false;
          console.info(err);
        });
    } else {
      console.log('No regId');
    }
  }

  isArray(tt: any): boolean {
    return tt && typeof tt === 'object';
  }

  getFormatedDate(start, end) {
    start = start || new Date();
    end = end || new Date();
    return {
      $gte: `${moment(start).format('YYYY-MM-DD')} ${this.timeFilter.startTime}`,
      $lte: `${moment(end).format('YYYY-MM-DD')} ${this.timeFilter.endTime}`
    };
  }

  moveToInstanceList(url: string) {
    const tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
    tt['processName'] = this.allData['integratedProcessName'];
    localStorage.setItem('wsAppData', JSON.stringify(tt));
    this.moveTo(url);
  }

  initDateFilter() {
    let startDate: any = new Date();
    const endDate = new Date();
    if (this.appData && this.appData.dataRangeTimeDiff) {
      const timeParam = this.appData.dataRangeTimeDiff.substr(this.appData.dataRangeTimeDiff.length - 1, this.appData.dataRangeTimeDiff.length);
      const timeValue = this.appData.dataRangeTimeDiff.substr(0, this.appData.dataRangeTimeDiff.length - 1);
      startDate = moment().subtract(timeValue, timeParam);
      startDate = startDate.startOf('day');

    } else {
      startDate.setDate(startDate.getDate() - 7);
      startDate.setHours(0, 0, 0, 0);
    }
    endDate.setHours(23, 59, 59, 999);
    this.timeFilter['startDate'] = startDate;
    this.timeFilter['endDate'] = endDate;
  }

  reopenFilters() {
    if (this.dataTablePastRequestData && this.dataTablePastRequestData.openedFilters) {
      this.allData = this.allData || {};
      this.postGetData();
      this.allData.filters = this.allData.filters || {};
      if (this.dataTablePastRequestData.openedFilters.advanceFilter !== null && this.dataTablePastRequestData.openedFilters.advanceFilter !== undefined) {
        this.allData.filters.noAdvanceFiltersSelected = this.dataTablePastRequestData.openedFilters.advanceFilter;
      }
      if (this.dataTablePastRequestData.openedFilters.dateRangeFilter !== null && this.dataTablePastRequestData.openedFilters.dateRangeFilter !== undefined) {
        this.allData.showDateRange = this.dataTablePastRequestData.openedFilters.dateRangeFilter;
      }
      if (this.dataTablePastRequestData.openedFilters.superFilter !== null && this.dataTablePastRequestData.openedFilters.superFilter !== undefined) {
        this.allData.showSuperFilter = this.dataTablePastRequestData.openedFilters.superFilter;
      }
    }
  }

  getRowClass(row) {
    return {
      'happ-summary': row.allSum
    };
  }

  rowCalculateFun(element) {
    for (const i in this.rowCalculations) {
      if (this.rowCalculations[i]['fields']) {
        element[i] = null;
        // tslint:disable-next-line:forin
        for (
          let j = 0;
          j < this.rowCalculations[i]['fields'].length;
          j++
        ) {
          const k = j + 1;
          if (
            !element[
            this.rowCalculations[i]['fields'][j]['operand']
            ] ||
            (element[
              this.rowCalculations[i]['fields'][j]['operand']
            ] &&
              parseFloat(
                element[
                this.rowCalculations[i]['fields'][j][
                'operand'
                ]
                ]
              ) === NaN)
          ) {
            element[
              this.rowCalculations[i]['fields'][j]['operand']
            ] = 0;
          } else {
            element[
              this.rowCalculations[i]['fields'][j]['operand']
            ] = parseFloat(
              element[
              this.rowCalculations[i]['fields'][j]['operand']
              ]
            );
          }
          switch (this.rowCalculations[i]['fields'][j]['operator']) {
            case '+':
              if (
                element[i] == null ||
                element[i] === undefined
              ) {
                element[i] =
                  element[
                  this.rowCalculations[i]['fields'][j][
                  'operand'
                  ]
                  ] +
                  (this.rowCalculations[i]['fields'][k] &&
                    element[
                    this.rowCalculations[i]['fields'][k][
                    'operand'
                    ]
                    ]
                    ? parseFloat(
                      element[
                      this.rowCalculations[i][
                      'fields'
                      ][k]['operand']
                      ]
                    )
                    : 0);
                j = j + 1;
              } else {
                element[i] +=
                  element[
                  this.rowCalculations[i]['fields'][j][
                  'operand'
                  ]
                  ];
              }

              break;
            case '-':
              if (
                element[i] == null ||
                element[i] === undefined
              ) {
                element[i] =
                  element[
                  this.rowCalculations[i]['fields'][j][
                  'operand'
                  ]
                  ] -
                  (this.rowCalculations[i]['fields'][k] &&
                    element[
                    this.rowCalculations[i]['fields'][k][
                    'operand'
                    ]
                    ]
                    ? parseFloat(
                      element[
                      this.rowCalculations[i][
                      'fields'
                      ][k]['operand']
                      ]
                    )
                    : 0);
                j = j + 1;
              } else {
                element[i] -=
                  element[
                  this.rowCalculations[i]['fields'][j][
                  'operand'
                  ]
                  ];
              }

              break;
            case '*':
              if (
                element[i] == null ||
                element[i] === undefined
              ) {
                element[i] =
                  element[
                  this.rowCalculations[i]['fields'][j][
                  'operand'
                  ]
                  ] *
                  (this.rowCalculations[i]['fields'][k] &&
                    element[
                    this.rowCalculations[i]['fields'][k][
                    'operand'
                    ]
                    ]
                    ? parseFloat(
                      element[
                      this.rowCalculations[i][
                      'fields'
                      ][k]['operand']
                      ]
                    )
                    : 1);
                j = j + 1;
              } else {
                element[i] *=
                  element[
                  this.rowCalculations[i]['fields'][j][
                  'operand'
                  ]
                  ];
              }
              break;
            case '/':
              if (
                element[i] == null ||
                element[i] === undefined
              ) {
                element[i] =
                  element[
                  this.rowCalculations[i]['fields'][j][
                  'operand'
                  ]
                  ] /
                  (this.rowCalculations[i]['fields'][k] &&
                    element[
                    this.rowCalculations[i]['fields'][k][
                    'operand'
                    ]
                    ]
                    ? parseFloat(
                      element[
                      this.rowCalculations[i][
                      'fields'
                      ][k]['operand']
                      ]
                    )
                    : 1);
                j = j + 1;
              } else {
                if (
                  element[
                  this.rowCalculations[i]['fields'][j][
                  'operand'
                  ]
                  ] !== 0
                ) {
                  element[i] /=
                    element[
                    this.rowCalculations[i]['fields'][
                    j
                    ]['operand']
                    ];
                }
              }
              break;
            case '%':
              if (
                element[i] == null ||
                element[i] === undefined
              ) {
                element[i] = element[this.rowCalculations[i]['fields'][j]['operand']] % (this.rowCalculations[i]['fields'][k] && element[this.rowCalculations[i]['fields'][k]['operand']]
                  ? parseFloat(element[this.rowCalculations[i]['fields'][k]['operand']]) : 1);
                j = j + 1;
              } else {
                if (element[this.rowCalculations[i]['fields'][j]['operand']] !== 0) {
                  element[i] = element[i] * (element[this.rowCalculations[i]['fields'][j]['operand']] / 100);
                }
              }
              break;
            default:
          }
        }
        element[i] = element[i]
          ? parseFloat(element[i]).toFixed(2)
          : element[i];
      } else {
        element[i] = 0;
      }
    }
    return element;
  }

  ngAfterViewInit(): void {
    // this.formChartAndInlineEditVariable();
  }

  resetcolCalculationValues() {
    this.colCalculations = this.colCalculations || {};
    // tslint:disable-next-line:forin
    for (const i in this.colCalculations) {
      this.colCalculations[i] = 0;
    }
  }

  formChartAndInlineEditVariable() {
    this.allData = this.allData || {};
    this.postGetData();
    this.allData['columns'] = this.allData['columns'] || [];
    this.allData['columns'].forEach(element => {
      if (element.cellTemplate) {
        this[element.cellTemplate]['render'] = element.render || [];
        element.cellTemplate = this[element.cellTemplate];
      }

      if (
        element['control_type'] &&
        element['control_type']['control_type'] === 'calculation'
      ) {
        this.rowCalculations[element['control_type']['label']] =
          element['control_type'];
      }

      if (
        element['control_type'] &&
        element['control_type']['inline_edit'] &&
        element['control_type']['category'] === 'radio'
      ) {
        if (element['control_type']['show_on_card']) {
          this.cardVarsArray.push({
            label: element['control_type']['label'],
            options: element['control_type']['options']
          });
          this.cardVars[element['control_type']['label']] = {};
          element['control_type']['options'].forEach(ele => {
            ele['count'] = 0;
            this.cardVars[element['control_type']['label']][
              ele.value || ele.title
            ] = ele;
          });
        }

        const options = {};
        element['control_type']['options'].forEach(ele => {
          options[ele.value || ele.title] = ele;
        });
        this.inlineEditCols[element['control_type']['label']] = options;
      }
    });
    setTimeout(() => {
      this.formatDataToChart();
    }, 500);
  }

  openScanqueueRecordsList() {
    this.router.navigate([`/offices/${this.officeId}/registries/scanqueuerecordslist/${this.allData['regId']}`
    ]);
  }

  moveTo(url) {
    if (url.includes('./') || url[0] === '/') {
      this.router.navigate([url], { relativeTo: this.route });
    } else {
      this.router.navigate([this.router.url + '/' + url]);
    }
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  inlineEdit(row, key, bool, col) {
    if (col && col.control_type && col.control_type.inline_edit) {
      if (!row.allSum) {
        if (this.inlineEditCell) {
          for (const i in this.inlineEditCell) {
            if (this.inlineEditCell.hasOwnProperty(i)) {
              this.inlineEditCell[i] = false;
            }
          }
        }
        this.inlineEditCell[key] = bool;
      }
    }
  }

  validateNumber(e: any, value, control_type) {
    const current = value.value || '';
    const input = String.fromCharCode(e.charCode);
    const next = current + input;
    if (control_type && control_type.allow_decimal) {
      const reg = new RegExp(`^\\d*(?:[.,]\\d{0,${control_type.decimal_places}})?$`);
      // const reg = /^\d*(?:[.,]\d{0,})?$/;
      if (!reg.test(next)) {
        e.preventDefault();
      }
    }

    // if (next && ((parseFloat(next) < control_type.min || parseFloat(next) > control_type.max))) {
    //   e.preventDefault();
    // }
  }

  actionMoveTo(rowIndex, application, data, button, url) {
    console.log("acrion move to");
    if (application === 'deleteRecord') {
      this.helperService.openConfirmPopUp('popup.are_you_sure_you_want_to_delete_record')
        .subscribe((res) => {
          if (res) {
            this.helperService.deleteRequest(`api/reg/${this.allData['regId']}/${data['_id']}`)
              .then(() => {
                this.getServerSideRecord();
                this.datatableService.onPositionRecordChange.next(true);
                this.helperService.openSnackBar('Deleted Successfully');
              }).catch(err => {
                this.helperService.openSnackBar(err || 'Error in deleting record');
                console.log('Error in deleting record', err);
              });
          }
      });
    } else if (
      application === 'app.oneRecordView.edit.popup' ||
      application === 'app.oneRecordView.edit.sideMenu' ||
      application === 'app.oneRecordView.add.popup'
    ) {
      this.addLoading = true;

      setTimeout(() => {
        this.addLoading = false;
      }, 5 * 1000);

      const recData = {};
      recData['regId'] = this.allData['regId'];
      recData['recId'] = data && data['_id'];
      recData['recData'] = data;
      recData['formFields'] = this.allData['columns'] || [];
      button.handler(recData);
    } else if (button.type === 'function') {
      const recData = {};

      data = data || {};
      this.updateReg(data._id, data._id, data._id, '_id', rowIndex, data)
        .then((tempData) => {
          recData['regId'] = this.allData['regId'];
          recData['recId'] = data['_id'];
          recData['childId'] = this.route.snapshot.params['childId'];
          recData['parentId'] = this.route.snapshot.params['parentId'];
          // tslint:disable-next-line:max-line-length
          this.helperService.getRequest(`api/registries/${recData['regId']}/registries-record/${recData['recId']}/replicate-record-register/${recData['childId']}?parentId=${recData['parentId']}`)
            .then(() => {
              this.snackBar.open('Saved', 'Okay', {
                duration: 2000
              });
            })
            .catch(err => {
              console.info(err);
              this.snackBar.open(err || 'Error Occured', 'Okay', {
                duration: 2000
              });
            });
          button.handler(recData);
        }).catch(err => {
          console.info(err);
        });
    } else if (url) {
      if (data) {
        this.allData.formId = (this.allData.ng5Form && this.allData.ng5Form._id);
        url = this.datatableService.formatUrl(url, this.allData);
        url = url.replace(':recId', data['_id']);
      }
      this.moveTo(url);
    } else if (application === 'app.message.start') {
      this.performAction(button, data);
    } else {
      if (button['placement']['headerActions']) {
        this.actionHandler(button);
      } else {
        this.actionClick(application, data, button);
      }
    }
  }

  actionClick(application, data, button) {
    if (button.type === 'function') {
      const recData = {};
      recData['regId'] = this.allData['regId'];
      recData['recId'] = data['_id'];
      recData['childId'] = this.route.snapshot.params['childId'];
      recData['parentId'] = this.route.snapshot.params['parentId'];
      // tslint:disable-next-line:max-line-length
      this.helperService
        .getRequest(
          `api/registries/${recData['regId']}/registries-record/${
          recData['recId']
          }/replicate-record-register/${
          recData['childId']
          }?parentId=${recData['parentId']}`
        )
        .then(() => {
          this.snackBar.open('Saved', 'Okay', {
            duration: 2000
          });
        })
        .catch(err => {
          console.info(err);
          this.snackBar.open(err || 'Error Occured', 'Okay', {
            duration: 2000
          });
        });
      button.handler(recData);
    } else if (application === 'app.customregistries.openChildRegister') {
      this.allData['openChildRegister'](button.params, data);
    } else if (application === 'app.persona24') {
      this.allData['openPersonaEdit'](data['_id']);
    } else {
      this.datatableService['editClickHandle'](this.allData, data);
    }
  }

  reset() {
    this.resetFilterValues();
    this.getServerSideRecord();
  }
  resetFilterValues() {
    this.dateTimeFilterType = (this.appData && this.appData.defaultDateFilter) || '##df_all##';
    this.valueFilter = {
      'registredDataTime': (this.appData && this.appData.defaultDateFilter) || '##df_all##'
    };
    console.log("this.allData.filters.values 1", this.allData.filters.values);
    this.initDateFilter();
    this.resetValues();
    this.resetcolCalculationValues();
  }

  filterIconClicked() {
    this.resetValues();
    this.resetcolCalculationValues();
    this.allData.filters.noAdvanceFiltersSelected = !this.allData.filters.noAdvanceFiltersSelected;
    this.dateRangeFilter();
    this.fastQuery = '';
    if (!this.allData.filters.noAdvanceFiltersSelected) {
      this.filter('');
    }
  }

  resetValues() {
    this.processFilters = {};
    this.filters = {};
    this.searchText = '';
    this.allData = this.allData || {};
    this.postGetData();
    this.allData.filters = this.allData.filters || {};
    this.valueFilter = this.valueFilter || {};
    for (const i in this.valueFilter) {
      if (i !== 'registredDataTime') {
        delete this.valueFilter[i];
      }
    }
    
    console.log("this.allData.filters.values 666", this.allData.filters.values, this.valueFilter);
    
  }

  search() {
    this.page.pageNumber = 0;
    if (this.requestData && this.requestData.page) {
      this.requestData.page.pageNumber = 0;
    }
    this.getFilters();
    this.resetcolCalculationValues();
    this.getServerSideRecord();
  }

  excel() {
    const reqData: any = JSON.parse(JSON.stringify(this.requestData));
    reqData.reportType = 'excel';
    this.helperService.postRequest(`api/reg/${this.regId}/report`, this.requestData)
    .then((result) => {
      const temp = {
        base64: result || '',
        mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        originalname: `${moment().format('YYYY:MM:DD HH:mm:ss')}.xlsx`
      };
      this.helperService.downloadDocument(temp);
    }).catch(err => {
      console.log(err);
    });
  }

  reportGenerationPopup(mqFilter?: any) {
    const requestData: any = JSON.parse(JSON.stringify(this.requestData));
    if (mqFilter) {
      requestData.mqFilter = (requestData.mqFilter) ? `${requestData.mqFilter} AND ${mqFilter}` : mqFilter;
    }
    const dialogRef = this.dialog.open(DtTableReportPopupComponent, {
      width: '600px',
      height: '600px',
      restoreFocus: false,

      panelClass: 'happ-form-builder-dialog',
      data: {
        requestData: requestData,
        regId: this.regId,
        googleSheet: this.sheetList,
        templateList: this.appData.templateList || [],
        page: this.page
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  pdf() {
    // this.getFilters();
    // const pdfUrl = `${environment.serverUrl}api/registries/${
    //   this.allData['regId']
    //   }/reports/pdf/v2?filter=${JSON.stringify(this.requestData.filters)}`;
    // this.reportGenrate('pdf', pdfUrl);
    const reqData: any = JSON.parse(JSON.stringify(this.requestData));
    reqData.templateId = '5d12855d3bdb9d1316a53777';
    reqData.reportType = 'pdf';
    this.helperService.postRequest(`api/reg/${this.regId}/report`, reqData)
    .then((result) => {
      const temp = {
        base64: result || '',
        mimetype: 'application/pdf',
        originalname: `${moment().format('YYYY:MM:DD HH:mm:ss')}.pdf`
      };
      this.helperService.downloadDocument(temp);
    }).catch(err => {
      console.log(err);
    });
  }

  reportGenrate(type, url) {
    const bodydata = {
      columns: this.allData.columns,
      defaultRegisterDateTimeSort: this.allData
        .defaultRegisterDateTimeSort,
      filterRecord: {},
      filters: this.requestData.filters,
      officeId: this.allData.officeId,
      page: this.allData.page,
      regId: this.allData.regId,
      search: {
        value: this.fastQuery || '',
        regex: false
      },
      sort: this.allData.sort
    };

    if (this.requestData['currentMQFilter'] && this.appData && this.appData['hardFilter']) {
      this.requestData['mqFilter'] = `${this.appData['hardFilter']} AND ${this.requestData['currentMQFilter']}`;
      // delete this.requestData['currentMQFilter'];
    } else if (this.requestData['currentMQFilter']) {
      this.requestData['mqFilter'] = this.requestData['currentMQFilter'];
      // delete this.requestData['currentMQFilter'];
    } else if (this.appData && this.appData['hardFilter']) {
      this.requestData['mqFilter'] = this.appData['hardFilter'];
    }

    bodydata['mqFilter'] = this.requestData['mqFilter'] || '';

    const win = window.open('_blank');
    const doc = win.document;
    doc.open('text/html');
    doc.write(
      `<html><head><title>Report Generate</title></head>
            <body style="display: flex;flex-direction: column;justify-content: center;align-items: center;text-align: center;min-height: 100vh;background-color: #f7f7f7;">
            <div><h3>Report Generate<h3/><br/><br/> <img src="https://rigp.pinbox24.com/assets/images/Processing.gif"/></div>
            <body></html>`
    );
    const a = document.createElement('a');
    document.body.appendChild(a);
    this.downloadFile(url, bodydata, function (blob) {
      doc.close();
      const urlBlob = URL.createObjectURL(blob);
      a.href = urlBlob;
      a.download =
        new Date().toISOString().slice(0, 10) + '_Report.' + type;
      a.click();
      win.close();
    });
  }

  downloadFile(url, body, success) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    const credentials =
      JSON.parse(localStorage.getItem('ngStorage-token')) || {};
    xhr.setRequestHeader('Authorization', credentials.token || '');
    xhr.setRequestHeader('officeId', this.allData.officeId || '');
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhr.send(JSON.stringify(body));
    xhr.responseType = 'blob';
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (success) {
          success(xhr.response);
        }
      }
    };
    xhr.send(null);
  }

  filter(query) {
    this.page.pageNumber = 0;
    if (this.requestData && this.requestData.page) {
      this.requestData.page.pageNumber = 0;
    }
    this.searchText = query;
    this.resetcolCalculationValues();
    this.getServerSideRecord();
  }

  dateRangeFilter() {
    // this.allData.showDateRange = !this.allData.showDateRange;
    // if (!this.allData.filters.noAdvanceFiltersSelected) {
    //   this.allData.filters.hideDateRange = false;
    // }
    if (this.allData.showDateRange) {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      this.allData.filters['startDate'] = startDate;
      this.allData.filters['endDate'] = endDate;
    }
  }

  formatDataToChart() {
    this.chartData = [];
    this.cardVarsArray.forEach(ele => {
      this.chartData[this.chartData.length] = [];
      ele.options.forEach(ele2 => {
        this.chartData[this.chartData.length - 1].push({
          name: ele2.value || ele2.title,
          value: this.cardVars[ele.label][ele2.value || ele2.title]
            .count
        });
      });
    });
  }

  getFilters() {
    if (this.datatableService && this.datatableService.instanceFilters && Object.keys(this.datatableService.instanceFilters)) {
      this.requestData['filterRecord'] = this.datatableService.instanceFilters;
    } else {
      this.requestData['filterRecord'] = {};
    }

    if ((this.allData.filters && this.allData.filters.noAdvanceFiltersSelected) || this.allData.showSuperFilter || this.allData.showDateRange) {
      console.log("this.allData.filters.values 777", this.allData.filters.values);
      this.valueFilter = this.valueFilter || {};
      if (this.allData && this.allData.showDateRange && this.dateTimeFilterType === '##df_customValue##') {
        // this.valueFilter['registredDataTime'] = this.getFormatedDate(this.timeFilter.startDate, this.timeFilter.endDate);
        this.requestData['filters'] = Object.assign(this.valueFilter, { 'registredDataTime': this.getFormatedDate(this.timeFilter.startDate, this.timeFilter.endDate) });
      } else {
        this.valueFilter['registredDataTime'] = this.dateTimeFilterType;
        this.requestData['filters'] = this.valueFilter;
      }
    } else {
      this.requestData['filters'] = {};
    }

    for (const i in this.requestData['filters']) {
      if (!this.requestData['filters'][i]) {
        delete this.requestData['filters'][i];
      }
    }

    this.filters = this.requestData['filters'];
  }

  testing(data) {
  }

  async updateReg(recId, value, prevVal, key, rowIndex, allValue = {}) {
    try {


      if (!this.allData['regId']) {
        this.snackBar.open('ERROR: Register Id not found', 'Okay', {
          duration: 2000
        });
        return;
      }

      console.log(recId, value, prevVal, key, rowIndex, allValue);
      allValue[key] = value;
      allValue = this.calculationOnSave(allValue);

      const tt = JSON.parse(JSON.stringify(allValue));
      delete tt['_id'];

      const data = {};
      this.processFilters = {};
      data[key] = value;
      await this.helperService.updateRegisterRecord(this.allData['regId'], recId, tt)
      if (this.allData['refreshOnEdit']) {
        this.getServerSideRecord();
      } else {
        this.getSum();
        this.datatableService.onPositionRecordChange.next(true);
        if (this.cardVars[key]) {
          this.cardVars[key][value].count += 1;
          if (
            this.cardVars[key][prevVal.value || prevVal.title]
          ) {
            this.cardVars[key][
              prevVal.value || prevVal.title
            ].count -= 1;
          }
          this.formatDataToChart();
        }

        if (this.inlineEditCols[key]) {
          this.rows[rowIndex][key] = this.inlineEditCols[key][
            value
          ];
        } else {
          this.rows[rowIndex][key] = value;
        }
        this.rowCalculateFun(this.rows[rowIndex]);
        if (
          this.colCalculations &&
          Object.keys(this.colCalculations).length > 0
        ) {
          for (const i in this.colCalculations) {
            if (i !== 'allSum') {
              this.colCalculations[i] = 0;
            }
          }
          this.rows.forEach((element, k) => {
            if (!element['allSum']) {
              for (const i in element) {
                if (
                  this.colCalculations[i] != null &&
                  this.colCalculations[i] !== undefined &&
                  element[i] &&
                  parseFloat(this.colCalculations[i]) !==
                  NaN &&
                  parseFloat(element[i]) !== NaN
                ) {
                  this.colCalculations[i] =
                    parseFloat(
                      this.colCalculations[i]
                    ) + parseFloat(element[i]);
                }
              }
            }
          });
          this.summaryArray = [];
          if (this.allData && this.allData.application === 'position') {
            this.allData.positionParentUpdate(this.colCalculations);
          }
          // format
          this.colCalculations = this.colCalculations || {};
          for (const i in this.colCalculations) {
            if (i !== 'allSum') {
              this.colCalculations[i] =
                this.colCalculations[i] &&
                this.colCalculations[i].toFixed(2);
            }
          }
          this.summaryArray.push(this.colCalculations);
        }
        this.rows = [...this.rows];
      }
      this.updateParent();
      return true;
    } catch (err) {
      console.info(err);
    }
  }

  updateParent() {
    if (
      JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) &&
      JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))[
      'apps'
      ] &&
      this.allData['extra'] &&
      this.allData['extra']['GDPR-RES-ID']
    ) {
      const GDPR_Resource = JSON.parse(
        localStorage.getItem('ngStorage-selectedOffice')
      )['apps']['gdpr-resources']['id'];
      const count = {
        conclusion1:
          this.cardVars['GDPR-Q-CON']['Zgodność']['count'] || 0,
        conclusion2:
          this.cardVars['GDPR-Q-CON']['Do usprawnienia']['count'] ||
          0,
        conclusion3:
          this.cardVars['GDPR-Q-CON']['Potencjalne ryzyko'][
          'count'
          ] || 0,
        conclusion4:
          this.cardVars['GDPR-Q-CON']['Niezgodny']['count'] || 0
      };
      this.helperService
        .updateRegisterRecord(
          GDPR_Resource,
          this.allData['extra']['GDPR-RES-ID'],
          count
        )
        .then(data => { })
        .catch(err => {
          console.info(err);
          this.snackBar.open(
            err || 'Error occured while updating data',
            'Okay',
            {
              duration: 2000
            }
          );
        });
    }
  }

  actionHandler(type) {
    if (type.application === 'app.customregistries.openChildRegister') {
      this.allData['openChildRegister'](type);
    } else {
      this.datatableService['AddClickHandle'](this.allData);
    }
  }

  openWSAppsSettings(tabIndex) {
    tabIndex = tabIndex || 0;
    if (!this.appData.integratedProcessId) {
      this.appData.integratedProcessId = this.allData['integratedProcessId'];
    }
    const dialogRef = this.dialog.open(WsAppsSettingsComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: {
        appData: this.appData,
        appList: this.workspaceData.appList,
        wsInfo: this.wsInfo,
        processRoles: this.appData.processRoles,
        requiredDataFields: this.appData.requiredDataFields,
        gvRequiredFields: this.appData.gvRequiredFields,
        tabIndex
      }
    });

    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appData = result;
        this.checkWSRequiredField();
      }
    });
  }


  openBulActionPopup(action?) {
    let isTask = false;
    this.makeReqBody(null, null);
    let taskObject = {};
    if (action && action.application === 'app.message.start') {
      isTask = true;
      taskObject = action.taskInfo.task;
    }

    const dialogRef = this.dialog.open(RecordBulkUpdateComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: {
        isTask: isTask,
        taskObject: taskObject,
        regId: this.allData.regId,
        regName: this.allData.regName,
        columnFields: this.allData.columns,
        fields: JSON.parse(JSON.stringify(this.helperService.getFormFlatField(this.allData.ng5Form['ng5Form']))),
        dtTableFilter: (this.requestData),
        mqHardFilter: (this.allData && this.allData.mqHardFilterString),
        recCount: (this.page && this.page.totalElements) || 0
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getServerSideRecord();
      }
    });
  }

  openDescriptionPopup(control_type, value) {
    const dialogRef = this.dialog.open(DescriptionPopupComponent, {
      width: control_type && control_type.fullWidth ? '100%' : '60%',
      height: control_type && control_type.fullWidth ? '100%' : '60%',
      panelClass: 'happ-form-builder-dialog',
      restoreFocus: false,
      data: { type: 'html', control_type, value: (value || (control_type && control_type.data)) }
    });
  }

  openInstructionPopup(data) {
    const dialogRef = this.dialog.open(DescriptionPopupComponent, {
      width: '60%',
      height: '60%',
      restoreFocus: false,
      panelClass: 'happ-form-builder-dialog',
      data: { type: 'data', value: data }
    });
  }

  savePrivateFilter() {
    this.confirmDialogRef = this.dialog.open(InputDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.inputPlaceholder =
      'Nazwa filtru';
    this.confirmDialogRef.componentInstance.confirmMessage =
      'Utwórz nowy filtr';
    this.confirmDialogRef.componentInstance.inputValue = '';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.privateFilter.addPrivateFilter(
          this.dataTablesParameters['filters'],
          result,
          this.allData.regId
        );
      }
      this.confirmDialogRef = null;
    });
  }

  triggerPrivateFilter(eve) {
    this.filters = eve || {};
    this.getServerSideRecord();
  }



  isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  updateParentRecord() {
    this.getSums(true, this.requestData)
      .then((data) => {
        this.summaryArray = this.summaryArray || [];
        this.summaryArray[0] = this.summaryArray[0] || {};
        data = (data) || {};
        delete data['_id'];
        // tslint:disable-next-line:forin
        for (const i in data) {
          if (data[i] && this.isFloat(data[i])) {
            this.summaryArray[0][i] = data[i];
          } else if (data[i]) {
            this.summaryArray[0][i] = parseFloat(parseFloat(data[i]).toFixed(2));
          }
        }
        this.summaryArray = [...this.summaryArray];

        this.helperService.updateRegisterRecord(this.datatableService.params['regId'], this.datatableService.params['recId'], data)
          .then(() => {
            this.datatableService.onRecordUpdated.next(data);
          }).catch(err => {
            console.info(err);
          });
      }).catch(err => {
        console.info(err);
      });
  }

  getSum() {
    if (this.allData && this.allData.summingColumns && this.allData.summingColumns.length) {
      this.getSums(false, this.requestData)
        .then((data) => {
          this.summaryArray = this.summaryArray || [];
          this.summaryArray[0] = this.summaryArray[0] || {};
          data = (data && data[0]) || {};
          delete data['_id'];
          // tslint:disable-next-line:forin
          for (const i in data) {
            if (data[i] && this.isFloat(data[i])) {
              this.summaryArray[0][i] = parseFloat(parseFloat(data[i]).toFixed(2));
            } else if (data[i]) {
              this.summaryArray[0][i] = data[i];
            }
          }
          this.summaryArray = [...this.summaryArray];

        }).catch(err => {
          console.info(err);
        });
    }
  }


  getSums(isPosition: boolean, requestData: any) {
    if (isPosition) {
      // delete requestData['filter'];
      // delete requestData['mqFilter'];
    }
    requestData['columns'] = this.allData.summingColumns || [];
    return this.helperService.postRequest(`api/registries/sum/${this.regId}`, requestData);
  }

  downloadPdf(docId) {
    this.helperService
      .getDocument(docId)
      .then(docData => {
        if (docData) {
          this.helperService.downloadDocument(docData);
        } else {
          this.snackBar.open('Data is null', 'Okay', {
            duration: 2000
          });
        }
      })
      .catch(err => {
        this.snackBar.open(err || 'Some error Occured', 'Okay', {
          duration: 2000
        });
      });
  }

  saveFilterToOffice() {
    this.helperService.openInputPopUp('Filter Name', 'You are about to save current filter in office document', '')
      .subscribe((data) => {
        if (data) {
          this._officeHelper.getLocalOfficeData()
            .then((officeData: IOfficeData) => {
              const tempFilter = {
                name: data,
                regId: this.regId,
                regName: this.allData['regName']
              };
              if (this.requestData) {
                if (this.requestData.filterRecord && Object.keys(this.requestData.filterRecord).length) {
                  tempFilter['filter'] = this.requestData.filterRecord;
                }
                if (this.requestData.filters && Object.keys(this.requestData.filters).length) {
                  if (tempFilter['filter']) {
                    // tslint:disable-next-line:forin
                    for (const i in this.requestData.filters) {
                      tempFilter['filter'][i] = this.requestData.filters[i];
                    }
                  } else {
                    tempFilter['filter'] = this.requestData.filters;
                  }
                }

                if (tempFilter['filter'] && Object.keys(tempFilter['filter']).length) {
                  tempFilter['filter'] = JSON.stringify(tempFilter['filter']);
                }

                if (this.requestData.mqFilter) {
                  tempFilter['mqFilter'] = this.requestData.mqFilter;
                }
              }
              const officeFilters = officeData.officeFilters || [];
              officeFilters.push(tempFilter as any);
              this._officeHelper.updateOfficeDoc({ officeFilters })
                .then(() => {
                  this._officeHelper.refreshLocalOfficeData();
                  this.refreshOfficeFilter++;
                  this.helperService.showInfo('info_and_errors.office_filter_saved', 'Success:', 'succcess');
                }).catch(err => {
                  this._officeHelper.refreshLocalOfficeData();
                  console.info(err);
                });
            }).catch(err => {
              console.info(err);
            });
        }
      });
  }

  calculationOnSave(data) {
    let tt = this.helperService.getXTypeVariables((this.allData.ng5Form && this.allData.ng5Form['ng5Form']), 'calculation_on_save') || [];
    tt = this.helperService.transformSort(tt, 'calculationSort') || [];
    tt.forEach(ele => {
      let sum = 0;
      if (ele['fields']) {
        sum += this.helperService.rowCalculateOnSave(ele, data);
      }
      data[ele['label']] = sum;
      // this is to save title too..
      if (ele['saveRange']) {
        ele['range'] = ele['range'] || [];
        ele['range'].forEach((range) => {
          if (sum >= range.min && sum <= range.max) {
            data[ele['rangeLabel']] = range.title || sum;
          }
        });
      }
    });
    return data;
  }

  getColumnSummary(ele) {
    return `--`;
  }

  officeFilterClicked(filter) {
    if (filter) {
      if (filter.filter) {
        const tt = JSON.parse(filter.filter);
        this.requestData.filters = this.requestData.filters || {};
        // tslint:disable-next-line:forin
        for (const i in tt) {
          this.requestData.filters[i] = tt[i];
        }
      }
      this.getServerSideRecord(null, filter.mqFilter);
    }
  }

  performAction(action, data) {
    if (action && action.application === 'app.message.start') {
      this.helperService.postRequest(`api/registries/execute_task/${this.regId}/${data._id}`, { taskObject: action.taskInfo.task })
        .then((data) => {
          this.helperService.openSnackBar('Task Executed');
          this.getServerSideRecord();
        }).catch(err => {
          console.info(err);
        });
    }
  }

  moveToProcessSettings(processId) {
    this.router.navigate([`/offices/${this.officeId}/process-settings/process-settings-list/process-settings-list/${processId}`]);
  }

  hardFilterClicked(value: any) {
    if (this.appData && this.appData.pullAllDataToView) {
      // this.requestData['currentMQFilter'] = value; 
      this.mqFilterString = value;
      const tt = (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
      const appSlug = tt['appUrlSlug'] || tt['appSlug'];
      localStorage.setItem(`$${appSlug}`, value);
      // this.rows = [...this.rows];
    } else {
      this.resetValues();
      this.fastQuery = '';
      this.requestData['currentMQFilter'] = value;
      this.mqFilterString = value;
      this.getServerSideRecord();
    }
  }

  cardOptionChanged({ optionData, recId }) {
    const updatedDoc = {};
    if (this.regId && optionData) {
      const dataIndex = _.findIndex(this.rows, { _id: recId });
      if (dataIndex !== -1 && this.rows[dataIndex]) {
        this.rows[dataIndex][optionData.keyName] = optionData.value;
        if (optionData.extraData) {
          this.rows[dataIndex] = {
            ...this.rows[dataIndex],
            ...optionData.extraData
          }
        }
        this.rows = [...this.rows];
        this.refresh = new Date().getTime();
      }
      updatedDoc[optionData.keyName] = optionData.value;
      this._changeDetectorRef.detectChanges();
      if (!optionData.doNotUpdate) {
        this.helperService.updateRegisterRecord(this.regId, recId, updatedDoc)
          .then(() => {
            this.helperService.showInfo('Updated the record', 'Success', 'success', { positionClass: 'toast-top-right', timeOut: 2000 });
          }).catch(err => {
            this.helperService.showInfo(err, 'Error', 'error');
          });
      }
    }
  }


  getStoredFilterForView() {
    // this.defaultHardFilter = null;
    const tt = (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
    const appSlug = tt['appUrlSlug'] || tt['appSlug'];
    if (localStorage.getItem(`$${appSlug}`)) {
      this.defaultHardFilter = localStorage.getItem(`$${appSlug}`);
      this.dateTimeFilterType = this.defaultHardFilter.dateTimeFilterType;
    } else {
      this.getDefaultHardFilter();
    }
  }

  getDefaultHardFilter() {
    if (!this.currentMQFilter && !this.defaultHardFilter) {
      this.allData.dtTableAdvanceFilters = this.allData.dtTableAdvanceFilters || [];
      const temp = this.allData.dtTableAdvanceFilters.filter((ele) => (ele && ele.defaultFilter));
      if (temp && temp.length) {
        this.defaultHardFilter = temp[0].value;
      }
    }
  }

  editRecord(recId: string) {
    if (recId === 'new') {
      const tempNew = (this.allData && this.allData.integratedProcessId) ? './recWithInstance' : './new';
      this.router.navigate([tempNew], { relativeTo: this.route });
    } else {
      this.router.navigate([`./${recId}`], { relativeTo: this.route });
    }
  }

  refreshTable() {
    this.getServerSideRecord();
  }

  onCustomDateChange(eve: any, label) {
    console.log("eve  =", eve, label);
    if (eve === "##df_customValue##") {
      this.valueFilter['df_'+label] = this.valueFilter['df_'+label] || {
        startDate: moment().format("YYYY-MM-DD"),
        startTime: "00:00",
        endDate: moment().add(7, "day").format("YYYY-MM-DD"),
        endTime: "23:59"
      };
    } else {
      delete this.valueFilter['df_'+label];
    }
  }

  inputValueChanged(temp, val, fieldLabel) {
    if (temp.key === ',' || temp.key === ' ') {
      if (val) {
        val = val.substr(0, (val.length - 1));
      }
      this.addItem(temp, val, fieldLabel);
    }
    this.filterValue[fieldLabel] = this._filterStates(val); 
  }

  addItem(eve: any, val, fieldLabel) {
    val = (eve && eve.option && eve.option.value) || val;
    const tempFilter = this.filterValue[fieldLabel].map((ele) => (ele.tag));
    console.log("val",val, this.items,tempFilter);
    this.items[fieldLabel] = this.items[fieldLabel] || [];
    if (tempFilter.includes(val) && !this.items[fieldLabel].includes(val)) {
      this.items[fieldLabel].push(val);
      this.valueFilter.$or = this.valueFilter.$or || [];
      this.valueFilter.$or = this.items[fieldLabel].map((ele) => ({ [fieldLabel]: {$regex: `((^${ele}$|^${ele},)|,${ele},|,${ele}$)`} }));
    }
  }

  removeItem(ele, fieldLabel) {
    console.log(ele);
    this.items[fieldLabel] = this.items[fieldLabel] || [];
    if (this.items[fieldLabel].includes(ele)) {
      this.items[fieldLabel].splice(this.items[fieldLabel].indexOf(ele), 1);
      this.valueFilter.$or = this.valueFilter.$or || [];
      this.valueFilter.$or = this.items[fieldLabel].map((ele) => ({ [fieldLabel]: {$regex: `((^${ele}$|^${ele},)|,${ele},|,${ele}$)`} }));
    }
  }

  private _filterStates(value: string) {
    const filterValue = (value && value.toLowerCase());
    return this.officeTags.filter(state => state.tag && state.tag.toLowerCase().indexOf(filterValue) === 0);
  }

  async getOfficeTags() {
    try {
      this.officeTags = await this.helperService.getRequest("api/record-tag");
    } catch (error) {
      console.log("Err = ", error);
    }
  }

  postGetData() {
    if (this.allData.filters && this.allData.filters.values) {
      this.allData.filters.values.forEach((ele) => {
        if (ele.control_type === 'input-tag') {
          this.filterValue[ele.label] = JSON.parse(JSON.stringify(this.officeTags))
        }
      });
    } else {
      this.filterValue = {};
    }
  }
  makeRefreshTable() {
    this.refreshTable();
  }
}
