<div [innerHtml]="styles | htmlWithStyle"></div>
<div [class]="field.class" *ngIf="field" [ngSwitch]="field.control_type">
  <!-- <<<<<<<<<<<<<<<  INPUT TEXT   >>>>>>>>>>>>>>>>> -->
  <div *ngSwitchCase="'text'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-input-text (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" (onError)="checkError($event)"
        [field]="field" [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-input-text>
    </div>
  </div>
  <div *ngSwitchCase="'input_tmpl'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-input-text (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" (onError)="checkError($event)"
        [field]="field" [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-input-text>
    </div>
  </div>
  <div *ngSwitchCase="'Input Text'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-input-text (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" (onError)="checkError($event)"
        [field]="field" [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-input-text>
    </div>
  </div>
  <div *ngSwitchCase="'ext-bank-check'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ext-bank-check [regRec]="tempService.formData" (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        (onError)="checkError($event)" [field]="field" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-ext-bank-check>
    </div>
  </div>
  <div *ngSwitchCase="'input-validation'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-input-record-validate [recId]="tempService.recId" [regId]="tempService.regId"
        (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" (onError)="checkError($event)" [field]="field"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-input-record-validate>
    </div>
  </div>
  <div *ngSwitchCase="'mailing-logs'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-mailing-log [recId]="tempService.recId"></app-mailing-log>
    </div>
  </div>
  <div *ngSwitchCase="'status'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-status-toggle (onError)="checkError($event)" [field]="field"
        [(model)]="tempService.formData[field.label]"></app-status-toggle>
    </div>
  </div>
  <div *ngSwitchCase="'email'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-email (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" (onError)="checkError($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-email>
    </div>
  </div>
  <div *ngSwitchCase="'event-form'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ss-event-form (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-ss-event-form>
    </div>
  </div>
  <div *ngSwitchCase="'event-form-config'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ss-event-form-config (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-ss-event-form-config>
    </div>
  </div>
  <div *ngSwitchCase="'currency-list'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-currency-list (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-currency-list>
    </div>
  </div>
  <div *ngSwitchCase="'ss-type-selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ss-type-select (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-ss-type-select>
    </div>
  </div>
  <div *ngSwitchCase="'template-selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ct-template-selection [regRec]="tempService.formData" (updateRecord)="onupdateRecord($event)"
        (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" (onError)="checkError($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-ct-template-selection>
    </div>
  </div>
  <div *ngSwitchCase="'service_selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ct-service-selection (onError)="checkError($event)" [field]="field" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-ct-service-selection>
    </div>
  </div>
  <div *ngSwitchCase="'geo_location'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-geo-location (updateFormDataObj)="updateFormDataObjHelper($event)" [regRec]="tempService.formData" (updateRecord)="onupdateRecord($event)"
        (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" (onError)="checkError($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-geo-location>
    </div>
  </div>
  <div *ngSwitchCase="'map_cluster'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-map-cluster [regRec]="tempService.formData" [field]="field" [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-map-cluster>
    </div>
  </div>
  <div *ngSwitchCase="'service_variant'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ct-service-variant (updateRecord)="onupdateRecord($event)" (onError)="checkError($event)" [field]="field"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-ct-service-variant>
    </div>
  </div>
  <div *ngSwitchCase="'office_selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <div fxLayout="row">
        <p>
          {{ ( tempService && tempService.formData && tempService.formData[field.label]) }}
        </p>
        <span fxFlex></span>
        <button mat-raised-button (click)="selectOffice(field)"> {{ 'ct.select_office' | translate }} </button>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'cal_office_selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-cal-office-selection (onError)="checkError($event)" [field]="field" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"
        [(modelName)]="tempService.formData[field.label + '_name']"></app-cal-office-selection>
    </div>
  </div>
  <div *ngSwitchCase="'shared_room_link'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-room-view-link [tempService]="tempService" [field]="field" [translateData]="translateData"
        [recData]="tempService.formData" [regId]="tempService.regId"></app-room-view-link>
    </div>
  </div>
  <div *ngSwitchCase="'textarea'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-input-text [isTextarea]="true" (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        (onError)="checkError($event)" [field]="field" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-input-text>
    </div>
    <!-- <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
        
        <mat-form-field floatLabel="always" appearance="outline" style="width: 100%">
            <mat-label>{{field.title}}</mat-label>
            <textarea fxFlex style="resize: none;" rows="{{ field.no_of_rows}}" matInput placeholder="{{field.title}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" [hidden]="field.hidden" [(ngModel)]="tempService.formData[field.label]"></textarea>
            <mat-hint align="start">
              <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle"></div>
            </mat-hint>
        </mat-form-field>
    </div> -->
  </div>
  <div *ngSwitchCase="'text_description'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <p class="px-8" [class]="field.class" [style.color]="field.styles.color"
        [style.font-size]="field.styles.font_size+'px'" style="margin: 0px;"
        [style.background]="field.styles.background" [style.text-align]="field.styles.align"
        [style.font-weight]="(field.styles.bold)? 'bold': ''">
        {{ ((tempService && tempService.formData && tempService.formData[field.label]) || field.data) | parseHtml:tempService.formData }}
      </p>
    </div>
  </div>
  <div *ngSwitchCase="'seprator'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <p class="px-8" [class]="field.class" [style.color]="field.styles.color"
        [style.font-size]="field.styles.font_size+'px'" style="margin: 0px;"
        [style.background]="field.styles.background" [style.text-align]="field.styles.align"
        [style.font-weight]="(field.styles.bold)? 'bold': ''">
        {{ ((tempService && tempService.formData && tempService.formData[field.label]) || field.data) | parseHtml:tempService.formData }}
      </p>
    </div>
  </div>
  <!-- <<<<<<<<<<<<<<<  INPUT TEXT ENDS  >>>>>>>>>>>>>>>>> -->

  <!-- <<<<<<<<<<<<<<<  NUMBER STARTS   >>>>>>>>>>>>>>>>> -->
  <div *ngSwitchCase="'number'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-number (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" (onError)="checkError($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-number>
    </div>
  </div>
  <div *ngSwitchCase="'currency'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-currency (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-currency>
    </div>
  </div>
  <div *ngSwitchCase="'phone'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-phone (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-phone>
    </div>
  </div>

  <div *ngSwitchCase="'date'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-date (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" (onError)="checkError($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-date>
    </div>
  </div>

  <div *ngSwitchCase="'date-time'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-date-time (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" (onError)="checkError($event)"
        [field]="field" [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-date-time>
    </div>
  </div>

  <div *ngSwitchCase="'calendar-range'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-calendar-range (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" (onError)="checkError($event)"
        [field]="field" [(end)]="tempService.formData['end']"
        [(start)]="tempService.formData['start']"></app-calendar-range>
    </div>
  </div>

  <div *ngSwitchCase="'date-range'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-date-range (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (onError)="checkError($event)" [(end)]="tempService.formData[field.endLabel]" [(year)]="tempService.formData[field.yearLabel]"
        [(start)]="tempService.formData[field.startLabel]"></app-date-range>
    </div>
  </div>

  <div *ngSwitchCase="'time'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-time (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" (onError)="checkError($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-time>
    </div>
  </div>

  <!-- <<<<<<<<<<<<<<<  NUMBER ENDS   >>>>>>>>>>>>>>>>> -->

  <!-- <<<<<<<<<<<<<<<  FILE STARTS   >>>>>>>>>>>>>>>>> -->
  <div *ngSwitchCase="'file'">
    <div class="fuse-white-bg" *ngIf="!field.hide">
      <app-file-upload (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [recId]="tempService.recId"
        [regId]="tempService.regId" [field]="field" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"
        [(recordMainDocument)]="tempService.formData['recordMainDocument']"></app-file-upload>
    </div>
  </div>


  <div *ngSwitchCase="'file_with_key'">
    <div class="fuse-white-bg" *ngIf="!field.hide">
      <app-file-upload-key (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        [parentId]="(tempService.params && tempService.params['parentId']) || ''"
        (recordCreated)="tempService.recordCreated($event)" [recId]="tempService.recId" [regId]="tempService.regId"
        [field]="field" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-file-upload-key>
    </div>
  </div>

  <div *ngSwitchCase="'file_preview'">
    <app-file-preview (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [translateData]="translateData"
      [isScanque]="tempService.regData && tempService.regData.scanqueue && tempService.regData.scanqueue.isChecked"
      [scanqueEditUrl]="(tempService.regData && tempService.regData.scanqueue && tempService.regData.scanqueue.sqObj) ? ('/workspace/'+tempService.params.workspace+'/'+tempService.params.appSlug+'/sq/'+tempService.recId+'/'+tempService.regData.scanqueue.sqObj) : ''"
      [fileId]="tempService.formData[ field.fileKey ||  'recordMainDocument']"></app-file-preview>
  </div>
  <!-- <<<<<<<<<<<<<<<  FILE ENDS   >>>>>>>>>>>>>>>>> -->

  <!-- <<<<<<<<<<<<<<<  OTHER STARTS   >>>>>>>>>>>>>>>>> -->
  <div *ngSwitchCase="'slider'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-slider (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-slider>
    </div>
  </div>

  <div *ngSwitchCase="'process_graph_view'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-process-graph-view (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        [showActivatedStatus]="field.show_activated_status" [translateData]="translateData"
        [showDownloadButton]="field.show_download_button" [activeStatusId]="tempService.formData['processStatusId']"
        [processId]="tempService.formData['procesId'] || tempService.formData['processId']"></app-process-graph-view>
    </div>
  </div>

  <div *ngSwitchCase="'ss_cat_selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ct-ss-cat-selection (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [formData]="tempService.ngForm" (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-ct-ss-cat-selection>
    </div>
  </div>

  <div *ngSwitchCase="'switch'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <div *ngIf="!field.hidden">
        <div class="happ-list-field" [class]="field.class">
          <div fxLayoutAlign="start center"
            [fxLayout]="(field.columnAlign === 'left' || field.columnAlign === 'right') ? 'row' : 'column'">
            <div *ngIf="field.columnAlign === 'left'" class="happ-default-title happ-title mr-12">
              <p> {{ field.title }} </p>
            </div>
            <div class="happ-list happ-content">
              <mat-slide-toggle [disabled]="field.showdisablecontroltype=='true' || field.disable"
                *ngIf="!field.showCheck" #tog
                (change)="tempService.formData[field.label] = (tog.checked)? field.trueVal: field.falseVal"
                [checked]="(tempService.formData[field.label] == field.trueVal)? true: false">
                {{tempService.formData[field.label]}}
              </mat-slide-toggle>
              <mat-checkbox [(ngModel)]="tempService.formData[field.label]" *ngIf="field.showCheck">{{
                (tempService.formData[field.label])? field.trueVal: field.falseVal }}</mat-checkbox>

            </div>
            <div *ngIf="field.columnAlign === 'right'" class="happ-default-title happ-title ml-12">
              <p> {{ field.title }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'Editor'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">

      <div>
        <app-form-editor [field]="field" [regRec]="tempService.formData"
          [(model)]="tempService.formData[field.label]"></app-form-editor>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'editor_tmpl'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">

      <div>
        <app-form-editor [field]="field" [regRec]="tempService.formData"
          [(model)]="tempService.formData[field.label]"></app-form-editor>
      </div>
    </div>
  </div>
  <!-- <<<<<<<<<<<<<<<  OTHER ENDS   >>>>>>>>>>>>>>>>> -->

  <!-- <<<<<<<<<<<<<<<  OPTION FIELDS STARTS   >>>>>>>>>>>>>>>>> -->
  <div *ngSwitchCase="'dropdown'">
    <app-dropdown (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
      [formData]="tempService.ngForm" (onError)="checkError($event)" [translateData]="translateData"
      [(model)]="tempService.formData[field.label]"></app-dropdown>
  </div>

  <div *ngSwitchCase="'auto-complete'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-option-based-auto-complete (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [formData]="tempService.ngForm" (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-option-based-auto-complete>
    </div>
  </div>

  <div *ngSwitchCase="'Radio Group'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-radio-group (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [formData]="tempService.ngForm" (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-radio-group>
    </div>
  </div>

  <div *ngSwitchCase="'List'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-list [field]="field" [formData]="tempService.ngForm" (onError)="checkError($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-list>
    </div>
  </div>

  <div *ngSwitchCase="'rwa-input'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-rwa-input (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (onError)="checkError($event)" [(rwa1)]="tempService.formData['rwa1']" [(rwa2)]="tempService.formData['rwa2']"
        [(rwa3)]="tempService.formData['rwa3']" [(rwa4)]="tempService.formData['rwa4']"
        [(rwaTitle)]="tempService.formData['rwaTitle']" [(rwaDescription)]="tempService.formData['rwaDescription']"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-rwa-input>
    </div>
  </div>

  <div *ngSwitchCase="'info-source-select'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ct-info-source-select (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (onError)="checkError($event)" [regId]="tempService.regId" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-ct-info-source-select>
    </div>
  </div>

  <div *ngSwitchCase="'shared-token'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-ct-shared-token-list (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (onError)="checkError($event)" [regId]="tempService.regId" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-ct-shared-token-list>
    </div>
  </div>

  <div *ngSwitchCase="'timeline'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-instance-timeline (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        [instanceId]="tempService.formData['instanceId']"></app-instance-timeline>
    </div>
  </div>

  <div *ngSwitchCase="'button-list'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-button-list [(parentId)]="tempService.formData['parentId']"
        (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" [formData]="tempService.ngForm"
        (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-button-list>
    </div>
  </div>
  <div *ngSwitchCase="'option-based-reg-value-selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-option-based-reg-value-selection [formData]="tempService.ngForm"
        (updateFormDataObj)="updateFormDataObjHelper($event)" (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        [field]="field" [formData]="tempService.ngForm" (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label + '_title']" [(parentId)]="tempService.formData['parentId']"
        [(value)]="tempService.formData[field.label]"></app-option-based-reg-value-selection>
    </div>
  </div>
  <div *ngSwitchCase="'option-based-reg-multi-value-selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-option-based-reg-value-multi-selection (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        [field]="field" [formData]="tempService.ngForm" (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-option-based-reg-value-multi-selection>
    </div>
  </div>
  <div *ngSwitchCase="'button-list-with-text'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-button-list-with-text (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [formData]="tempService.ngForm"
        [field]="field" (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-button-list-with-text>
    </div>
  </div>
  <div *ngSwitchCase="'input-tag'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-button-list-with-tag (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [regId]="tempService.regId"
        [recId]="tempService.recId" [formData]="tempService.ngForm" [field]="field" (onError)="checkError($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-button-list-with-tag>
    </div>
  </div>
  <!-- <<<<<<<<<<<<<<<  OPTION FIELDS ENDS   >>>>>>>>>>>>>>>>> -->

  <!-- <<<<<<<<<<<<<<<  MULTI SELECT FIELDS STARTS   >>>>>>>>>>>>>>>>> -->
  <div *ngSwitchCase="'checkbox'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-checkbox (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [formData]="tempService.ngForm" (onError)="checkError($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-checkbox>
    </div>
  </div>
  <!-- <<<<<<<<<<<<<<<  MULTI SELECT FIELDS ENDS   >>>>>>>>>>>>>>>>> -->

  <!-- signatura -->
  <!-- <<<<<<<<<<<<<<<  ADVANCE FIELDS STARTS   >>>>>>>>>>>>>>>>> -->
  <div *ngSwitchCase="'position'">
    <div *ngIf="!field.hide">
      <app-position (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (updateParentOnSave)="onupdateParentOnSave($event)" [regRec]="tempService.formData"
        (getRecord)="onGetRecord($event)" [recId]="tempService.recId" [signature]="tempService.formData['signatura']"
        [regId]="tempService.regId" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-position>
    </div>
  </div>
  <div *ngSwitchCase="'slot_selection'">
    <div *ngIf="!field.hide">
      <app-slot-selection-settings (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (updateParentOnSave)="onupdateParentOnSave($event)" [regRec]="tempService.formData"
        (getRecord)="onGetRecord($event)" [recId]="tempService.recId" [signature]="tempService.formData['signatura']"
        [regId]="tempService.regId" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-slot-selection-settings>
    </div>
  </div>
  <div *ngSwitchCase="'mailing-list-action'">
    <div *ngIf="!field.hide">
      <app-mailing-list-action (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        (updateParentOnSave)="onupdateParentOnSave($event)" [regRec]="tempService.formData"
        (getRecord)="onGetRecord($event)" [recId]="tempService.recId" [signature]="tempService.formData['signatura']"
        [regId]="tempService.regId" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-mailing-list-action>
    </div>
  </div>
  <div *ngSwitchCase="'shared-service-position'">
    <div *ngIf="!field.hide">
      <app-shared-service-position [recId]="tempService.recId"></app-shared-service-position>
    </div>
  </div>
  <div *ngSwitchCase="'delivery_address'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-delivery-address (onError)="checkError($event)" (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        [field]="field" [regRec]="tempService.formData" (updateAddress)="onupdateAddress($event)"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-delivery-address>
    </div>
  </div>

  <div *ngSwitchCase="'contact_pick'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-contact-pick (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [regRec]="tempService.formData" [(modelId)]="tempService.formData[field.label+'_id']"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-contact-pick>
    </div>
  </div>

  <div *ngSwitchCase="'excel_import'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-excel-import (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-excel-import>
    </div>
  </div>

  <div *ngSwitchCase="'user_selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-user-selection (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" (onError)="checkError($event)"
        [field]="field" [regId]="tempService.regId" [recId]="tempService.recId"
        [(responsibleId)]="tempService.formData['responsibleId']" [(emailModel)]="tempService.formData[field.label]"
        [(fullNameModel)]="tempService.formData[field.label+'_fullName']"
        [(shortCodeModel)]="tempService.formData[field.label+'_shortCode']"></app-user-selection>
    </div>
  </div>

  <div *ngSwitchCase="'group_selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-group-selection (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-group-selection>
    </div>
  </div>

  <div *ngSwitchCase="'org_selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-org-selection (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-org-selection>
    </div>
  </div>

  <div *ngSwitchCase="'contact-search'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-contact-search (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [(companyName)]="tempService.formData[field.label + 'companyName']" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-contact-search>
    </div>
  </div>

  <div *ngSwitchCase="'select_template'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-select-template [templateList]="tempService.ngForm.formTemplates"
        (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-select-template>
    </div>
  </div>

  <div *ngSwitchCase="'record_comment'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-record-comment [field]="field" [regId]="tempService.regId" [recId]="tempService.recId"
        [translateData]="translateData"></app-record-comment>
    </div>
  </div>

  <div *ngSwitchCase="'record_checklist'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-record-checklist [field]="field" [regId]="tempService.regId" [recId]="tempService.recId"
        [translateData]="translateData"></app-record-checklist>
    </div>
  </div>

  <!-- <<<<<<<<<<<<<<<  ADVANCE FIELDS ENDS   >>>>>>>>>>>>>>>>> -->

  <div *ngSwitchCase="'one_record_document'">
    <div class="fuse-white-bg" *ngIf="!field.hide">
      <app-one-record-document (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        [(recordMainDocument)]="tempService.formData['recordMainDocument']" [field]="field" [regId]="tempService.regId"
        (updateRecord)="onupdateRecord($event)" [recId]="tempService.recId"
        [templates]="tempService.ngForm.formTemplates" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]"></app-one-record-document>
    </div>
  </div>

  <div *ngSwitchCase="'case-to-rec-id'">
    <div class="fuse-white-bg">
      <app-case-to-rec-id (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [translateData]="translateData"
        [(model)]="tempService.formData[field.label]" [field]="field"
        [(parentModel)]="tempService.formData['parentId']"></app-case-to-rec-id>
    </div>
  </div>

  <div *ngSwitchCase="'reg_value_selection'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-reg-value-selection [recordData]="tempService.formData" (updateFormDataObj)="updateFormDataObjHelper($event)"
        (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field" [regId]="tempService.regId"
        [recId]="tempService.recId" [(model2)]="tempService.formData[field.label + '_value']"
        [translateData]="translateData" [(model)]="tempService.formData[field.label]"></app-reg-value-selection>
    </div>
  </div>

  <div *ngSwitchCase="'related_records'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <related-records-list (onFieldValueUpdated)="onFieldValueUpdateEvent($event)" [field]="field"
        [recData]="tempService.formData" [regId]="tempService.regId" [translateData]="translateData"
        [(contactId)]="tempService.formData['_id']"></related-records-list>
    </div>
  </div>

  <div *ngSwitchCase="'electronic_sender_country'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-electronic-sender-country [field]="field"
        [(model)]="tempService.formData[field.label]"></app-electronic-sender-country>
    </div>
  </div>


  <div *ngSwitchCase="'create_branch'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide">
      <app-create-branch [signatura]="tempService.formData['signatura']" [recId]="tempService.recId"
        [regId]="tempService.regId" (onFieldValueUpdated)="onFieldValueUpdateEvent($event)"
        [(model)]="tempService.formData[field.label]" [(otherInfo)]="tempService.formData[field.label + '_otherInfo']"
        [field]="field" [translateData]="translateData"></app-create-branch>
    </div>
  </div>

  <div *ngSwitchCase="'process_status'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide && field.showOnForm">
      <div class="my-8" *ngIf="tempService.error && tempService.error.length === 0">
        <app-process-status [translateData]="translateData" [(model)]="tempService.formData"
          (updateRecord)="onprocessUpdateRecord($event)" [field]="field"></app-process-status>
      </div>
      <div fxLayoutAlign="center center" *ngIf="tempService.error && tempService.error.length > 0">
        <h3>
          {{ 'ONE_REC.REMOVE_ALL_ERRORS' | translate }}
        </h3>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'move-to-any-status'">
    <div class="fuse-white-bg happ-left-border">
      <app-move-to-any-status [translateData]="translateData" [(model)]="tempService.formData"
        (updateRecord)="onprocessUpdateRecord($event)" [field]="field"></app-move-to-any-status>
    </div>
  </div>
  <div *ngSwitchCase="'standalone_buttons'">
    <div class="fuse-white-bg happ-left-border" *ngIf="!field.hide && field.showOnForm">
      <div class="my-8" *ngIf="tempService.error && tempService.error.length === 0">
        <app-standalone-buttons [translateData]="translateData" [(model)]="tempService.formData"
          (updateRecord)="onprocessUpdateRecord($event)" [field]="field"></app-standalone-buttons>
      </div>
      <div fxLayoutAlign="center center" *ngIf="tempService.error && tempService.error.length > 0">
        <h3>
          {{ 'ONE_REC.REMOVE_ALL_ERRORS' | translate }}
        </h3>
      </div>
    </div>
  </div>
</div>