import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { DtFileListPopupComponent } from '../dt-file-list-popup/dt-file-list-popup.component';
import { TranslateService } from '@ngx-translate/core';
var DatatableRowTmpComponent = /** @class */ (function () {
    function DatatableRowTmpComponent(router, dialog, translate, helperService) {
        var _this = this;
        this.router = router;
        this.dialog = dialog;
        this.translate = translate;
        this.helperService = helperService;
        this.transData = {};
        this.col = {};
        this.column = {};
        this.openDescriptionPopupOutput = new EventEmitter();
        this.refreshTable = new EventEmitter();
        this.inlineEditOutput = new EventEmitter();
        this.updateRegOutput = new EventEmitter();
        this.actionMoveToOutput = new EventEmitter();
        this.moveToOutput = new EventEmitter();
        this.downloadPdfEvent = new EventEmitter();
        this.transData = this.helperService.translationData;
        this.translate.onLangChange.subscribe(function (event) {
            _this.transData = _this.translate.translations[_this.translate.currentLang] || {};
        });
    }
    DatatableRowTmpComponent.prototype.openDescriptionPopup = function () {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        this.openDescriptionPopupOutput.emit(props);
    };
    DatatableRowTmpComponent.prototype.inlineEdit = function () {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        this.inlineEditOutput.emit(props);
    };
    DatatableRowTmpComponent.prototype.updateReg = function () {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        this.updateRegOutput.emit(props);
    };
    DatatableRowTmpComponent.prototype.actionMoveTo = function () {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        this.actionMoveToOutput.emit(props);
    };
    DatatableRowTmpComponent.prototype.moveTo = function () {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        this.moveToOutput.emit(props);
    };
    DatatableRowTmpComponent.prototype.downloadPdf = function (id) {
        this.downloadPdfEvent.emit(id);
    };
    DatatableRowTmpComponent.prototype.moveToParent = function (recId) {
        if (localStorage.getItem('selected-workspace')) {
            this.router.navigateByUrl("/workspace/" + localStorage.getItem('selected-workspace') + "/record-ws/" + recId);
        }
    };
    Object.defineProperty(DatatableRowTmpComponent.prototype, "isObject", {
        get: function () {
            return (typeof (this.value) === 'object');
        },
        enumerable: true,
        configurable: true
    });
    DatatableRowTmpComponent.prototype.removeFromMailingList = function (row, operation) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var confirmMessage;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    confirmMessage = '';
                    switch (operation) {
                        case "unSubscribeThis":
                            confirmMessage = 'Unsubscribe';
                            break;
                        case "removeFromList":
                            confirmMessage = 'Remove from list';
                            break;
                        case "unBlackList":
                            confirmMessage = 'Moved back to mailing list';
                            break;
                    }
                    this.helperService.openConfirmPopUp("Are you sure you want to " + confirmMessage + "?")
                        .subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var message;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!data) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.helperService.getRequest("api/mailing-list-operation/" + this.datatableService.params.recId + "/" + operation + "?email=" + row.email)];
                                case 1:
                                    _a.sent();
                                    message = '';
                                    switch (operation) {
                                        case "unSubscribeThis":
                                            message = 'Unsubscribe success';
                                            break;
                                        case "removeFromList":
                                            message = 'Remove from list success';
                                            break;
                                        case "unBlackList":
                                            message = 'Moved back to mailing list';
                                            break;
                                    }
                                    this.helperService.showInfo(message, "success", "success");
                                    this.refreshTable.emit(true);
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    alert(error);
                }
                return [2 /*return*/];
            });
        });
    };
    DatatableRowTmpComponent.prototype.openFilePopup = function (row) {
        var dialogRef = this.dialog.open(DtFileListPopupComponent, {
            width: '600px',
            height: '600px',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: {
                row: row
            }
        });
    };
    return DatatableRowTmpComponent;
}());
export { DatatableRowTmpComponent };
