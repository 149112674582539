import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { DtFileListPopupComponent } from '../dt-file-list-popup/dt-file-list-popup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-row-header-tmp',
  templateUrl: './datatable-row-tmp.component.html',
  styleUrls: ['./datatable-row-tmp.component.scss']
})
export class DatatableRowTmpComponent { 

    transData: any = {};

    @Input() col: any = {};
    @Input() value: any;
    @Input() row: any;
    @Input() rowIndex: any;
    @Input() colIndex: any;
    @Input() column: any = {};
    @Input() inlineEditCell: any;
    @Input() appData: any;
    @Input() datatableService: any;

    @Output() openDescriptionPopupOutput = new EventEmitter();
    @Output() refreshTable = new EventEmitter();
    @Output() inlineEditOutput = new EventEmitter();
    @Output() updateRegOutput = new EventEmitter();
    @Output() actionMoveToOutput = new EventEmitter();
    @Output() moveToOutput = new EventEmitter();
    @Output() downloadPdfEvent = new EventEmitter();

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private translate: TranslateService,
        private helperService: HelpersService
        ) {
        this.transData = this.helperService.translationData;
        this.translate.onLangChange.subscribe((event: any) => {
            this.transData = this.translate.translations[this.translate.currentLang] || {};
        });
    }

    openDescriptionPopup(...props) {
        this.openDescriptionPopupOutput.emit(props);
    }

    inlineEdit(...props) {
        this.inlineEditOutput.emit(props);
    }

    updateReg(...props) {
        this.updateRegOutput.emit(props);
    }

    actionMoveTo(...props) {
        this.actionMoveToOutput.emit(props);
    }

    moveTo(...props) {
        this.moveToOutput.emit(props);
    }

    downloadPdf(id: string) {
        this.downloadPdfEvent.emit(id);
    }

    moveToParent(recId: string) {
        if (localStorage.getItem('selected-workspace')) {
            this.router.navigateByUrl(`/workspace/${localStorage.getItem('selected-workspace')}/record-ws/${recId}`);
        }
    }

    get isObject() {
        return (typeof(this.value) === 'object');
    }

    async removeFromMailingList(row: any, operation: string) {
        try {
            let confirmMessage = '';
            switch (operation) {
                case "unSubscribeThis":
                    confirmMessage = 'Unsubscribe';
                    break;
                case "removeFromList":
                    confirmMessage = 'Remove from list';
                    break;
                case "unBlackList":
                    confirmMessage = 'Moved back to mailing list';
                    break;

            }
            this.helperService.openConfirmPopUp(`Are you sure you want to ${confirmMessage}?`)
            .subscribe(async (data) => {
                if (data) {
                    await this.helperService.getRequest(`api/mailing-list-operation/${this.datatableService.params.recId}/${operation}?email=${row.email}`)
                    let message = '';
                    switch (operation) {
                        case "unSubscribeThis":
                            message = 'Unsubscribe success';
                            break;
                        case "removeFromList":
                            message = 'Remove from list success';
                            break;
                        case "unBlackList":
                            message = 'Moved back to mailing list';
                            break;
        
                    }
                    this.helperService.showInfo(message, "success", "success");
                    this.refreshTable.emit(true);
                }
            })
        } catch (error) {
            alert(error);
        }
    }

    openFilePopup(row: any) {
        const dialogRef = this.dialog.open(DtFileListPopupComponent, {
            width: '600px',
            height: '600px',
            restoreFocus: false,
      
            panelClass: 'happ-form-builder-dialog',
            data: {
                row
            }
          });
    }
}
