import * as tslib_1 from "tslib";
import { Store, StoreConfig } from '@datorama/akita';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
var isUserAdmin = function () {
    try {
        var officeDoc = JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) || {};
        var profileDoc = JSON.parse(localStorage.getItem('ngStorage-profile')) || {};
        var usrDoc = _.find(officeDoc.users || [], { id: profileDoc.profileId });
        console.log("Office doc in auth", officeDoc);
        if (usrDoc && usrDoc['isAdmin']) {
            return { isAdmin: true };
        }
        else {
            return { isAdmin: false };
        }
    }
    catch (error) {
        throw error;
    }
};
var ɵ0 = isUserAdmin;
function getOfficeInfo() {
    try {
        var officeDoc = JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) || {};
        return {
            officeId: (officeDoc && officeDoc._id) || '',
            officeName: (officeDoc && officeDoc.name) || '',
        };
    }
    catch (err) {
        throw err;
    }
}
function getProfileInfo() {
    try {
        var profileDoc = JSON.parse(localStorage.getItem('ngStorage-profile')) || {};
        return {
            email: (profileDoc.email) || '',
            profileId: (profileDoc.profileId) || '',
            invitationCount: (profileDoc.invitationCount) || 0,
            defaultLang: localStorage.getItem('selectedLanguage') || (profileDoc.defaultLang) || 'en'
        };
    }
    catch (error) {
        throw error;
    }
}
function getToken() {
    try {
        var tokenInfo = JSON.parse(localStorage.getItem('ngStorage-token')) || {};
        return {
            token: tokenInfo.token || null
        };
    }
    catch (error) {
        throw error;
    }
}
export function createInitialState() {
    try {
        return tslib_1.__assign({}, getToken(), { redirectUrl: null }, getOfficeInfo(), getProfileInfo(), isUserAdmin());
    }
    catch (error) {
        console.error(error);
        return {
            officeId: '',
            officeName: '',
            email: '',
            profileId: '',
            invitationCount: 0,
            defaultLang: 'en',
            token: null,
            isAdmin: false,
            redirectUrl: '/login'
        };
    }
}
var AuthStore = /** @class */ (function (_super) {
    tslib_1.__extends(AuthStore, _super);
    function AuthStore() {
        return _super.call(this, createInitialState()) || this;
    }
    AuthStore.ngInjectableDef = i0.defineInjectable({ factory: function AuthStore_Factory() { return new AuthStore(); }, token: AuthStore, providedIn: "root" });
    AuthStore = tslib_1.__decorate([
        StoreConfig({ name: 'auth', resettable: true }),
        tslib_1.__metadata("design:paramtypes", [])
    ], AuthStore);
    return AuthStore;
}(Store));
export { AuthStore };
export { ɵ0 };
