<!-- for category radio type NON EDITING MODE -->
  <!-- templating other constoll type -->
  <div class="happ-full-area"
      [class]="'happ-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')"
      *ngIf="col && col.control_type && col.control_type.control_type && (!inlineEditCell[col.control_type.label+colIndex+rowIndex])">
      <div class="happ-full-area"
          [ngSwitch]="col.control_type.control_type">
          <div *ngSwitchCase="'date'">
              <p class="happ-full-area" *ngIf="value"
                  style="width: 100%; height: 80%;">
                  {{ value | date: 'yyyy-MM-dd' }} </p>
          </div>
          <div *ngSwitchCase="'date-time'">
              <p style="width: 100%; height: 100%;" *ngIf="value">
                  {{ value | date: 'yyyy-MM-dd HH:mm' }} </p>
          </div>
          <div *ngSwitchCase="'time'">
              <p class="happ-full-area" *ngIf="value"
                  style="width: 100%; height: 80%;">
                  <!-- {{ value | date: 'HH:mm' }} --> {{ value }}
              </p>
          </div>
          <div *ngSwitchCase="'status'">
            <app-status-toggle [hideTitle]="true"
            [field]="col.control_type"
            (valChange)="updateReg(row._id, inputVal.model, value, col.control_type.label, rowIndex)"
            #inputVal [model]="value"></app-status-toggle>
          </div>
          <div class="happ-full-area" *ngSwitchCase="'Editor'">
              <div *ngIf="!col.control_type.isPopup"
                  style="width: 100%; height: 80%;"
                  [innerHTML]="value | translateHtml:transData | htmlWithStyle">
              </div>
              <div *ngIf="col.control_type.isPopup"
                  style="width: 100%; height: 80%;">
                  <button *ngIf="value"
                      (click)="openDescriptionPopup(col.control_type, value)"
                      mat-icon-button>
                      <mat-icon>info</mat-icon>
                  </button>
              </div>
          </div>
          <div class="happ-full-area" *ngSwitchCase="'grid_link'">
              <a [href]="value"> {{ col.control_type.title | translate }} </a>
          </div>
          <div *ngSwitchCase="'dt_table_group_field'">
              <div *ngIf="!col.control_type.isPopup"
                  style="width: 100%; height: 80%;">
                  <div class="pad-left"
                      [innerHTML]="value | translateHtml:transData | htmlWithStyle"></div>
              </div>
              <div *ngIf="col.control_type.isPopup"
                  style="width: 100%; height: 80%;">
                  <button *ngIf="value"
                      (click)="openDescriptionPopup(col.control_type, value)"
                      mat-icon-button>
                      <mat-icon>info</mat-icon>
                  </button>
              </div>
          </div>
          <div *ngSwitchCase="'number'" class="happ-full-area"
              (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)">
              <p *ngIf="value && col.control_type.decimal_places"
                  class="happ-full-area"
                  style="width: 100%; height: 80%; min-width: 35px;">
                  {{ value | number:'1.'+col.control_type.decimal_places+'-'+col.control_type.decimal_places:'pl' }}
              </p>
              <p *ngIf="!value || !col.control_type.decimal_places"
                  class="happ-full-area"
                  style="width: 100%; height: 80%; min-width: 35px;">
                  {{ value }} </p>
          </div>
          <div *ngSwitchCase="'currency'" class="happ-full-area"
              (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)">
              <p *ngIf="value && col.control_type.decimal_places"
                  class="happ-full-area"
                  style="width: 100%; height: 80%; min-width: 35px;">
                  {{ value | currency:(col.control_type.currencyType || 'USD'):'symbol':'1.'+col.control_type.decimal_places+'-'+col.control_type.decimal_places:'pl' }}
              </p>
              <p *ngIf="!value || !col.control_type.decimal_places"
                  class="happ-full-area"
                  style="width: 100%; height: 80%; min-width: 35px;">
                  {{ value }} </p>
          </div>
          <div *ngSwitchCase="'text'" class="happ-full-area"
              (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)">
              <div class="m-0 w-100-p h-100-p"
                  *ngIf="col.control_type && col.control_type.colorOptions && !col.control_type.isPopup">
                  <div style="min-width: 35px;"
                      [class]="(col.control_type.colorOptions[value] && col.control_type.colorOptions[value].bgColor)+'-bg '+(col.control_type.colorOptions[value] && col.control_type.colorOptions[value].color)+'-fg m-0 w-100-p h-100-p text-center'"
                      fxLayoutAlign="center center"> {{ value }}
                  </div>
              </div>
              <p style="min-width: 35px;"
                  *ngIf="col.control_type && !col.control_type.colorOptions && !col.control_type.isPopup">
                  {{ value }} </p>
              <div *ngIf="col.control_type.isPopup"
                  style="width: 100%; height: 80%;">
                  <button *ngIf="value"
                      (click)="openDescriptionPopup(col.control_type, value)"
                      mat-icon-button>
                      <mat-icon>info</mat-icon>
                  </button>
              </div>
          </div>
          <div class="happ-full-area" class="happ-full-area"
              *ngSwitchCase="'button-list'">
              <div class="happ-display-value"
                  style="width: 100%;"
                  *ngIf="!col.control_type.inline_edit && col.control_type.multiple && isObject">
                   <div fxLayout="row wrap"
                      *ngFor="let tt of value | keys; let i=index">
                      <div *ngIf="tt.value"
                          style="border: 1px solid rgba(220,220,220,0.5); padding: 3px; margin: 3px; border-radius: 2px;">
                          {{ tt.key }} </div>
                  </div>
              </div>
              <p class="happ-display-value"
                  style="width: 100%;"
                  *ngIf="!col.control_type.inline_edit && !col.control_type.multiple && value">
                  <span *ngFor="let i of col.control_type.options">
                      <span
                          *ngIf="i.value == value || i.title == value">
                          {{ i.title || i.value }} </span>
                  </span>
              </p>
              <div class="happ-full-area"
                  (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)"
                  *ngIf="col.control_type.inline_edit">
                  <ng-container *ngIf="col.control_type.userRegister">
                      <p class="m-0">
                        value
                      </p>
                    </ng-container>
                    <ng-container *ngIf="!col.control_type.userRegister">
                        <ng-container *ngFor="let i of col.control_type.options">
                          <ng-container
                              *ngIf="i.value == value || i.title == value">
                              <div [class]="'happ-full-area happ-inline-button '+(i.background)+'-bg '+(i.color)+'-fg' + ' happ-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')">
                                  <p class="m-0">
                                      {{ i.title || i.value }} 
                                  </p>
                          </div>
                          </ng-container>
                      </ng-container>
                    </ng-container>
              </div>
          </div>
          <div class="happ-full-area" class="happ-full-area"
              *ngSwitchCase="'calculation_on_save'">
              <div class="w-100-p"
                  *ngIf="col.control_type.range && col.control_type.range.length > 0">
                  <div *ngFor="let i of col.control_type.range">
                      <div class="happ-full-area"
                          *ngIf="value >= i.min && value <= i.max">
                          <div class="happ-full-area happ-inline-button"
                              [class]="(i.background)+'-bg '+(i.color)+'-fg' + ' happ-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')">
                              <p class="m-0">
                                {{ i.title || value | number:'1.0-2':'pl' }}
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
              <p *ngIf="!col.control_type.range || (col.control_type.range && col.control_type.range.length === 0)"
                  class="happ-display-value align-txt-ab happ-full-area"
                  style="width: 100%; height: 80%;">
                  {{ value | number:'1.0-2':'pl' }} </p>
          </div>
          <div class="happ-full-area" class="happ-full-area"
              *ngSwitchCase="'calculation'">
              <div class="w-100-p"
                  *ngIf="col.control_type.range && col.control_type.range.length > 0">
                  <div *ngFor="let i of col.control_type.range">
                      <div class="happ-full-area"
                          *ngIf="value >= i.min && value <= i.max">
                          <div class="happ-full-area happ-inline-button"
                              [class]="(i.background)+'-bg '+(i.color)+'-fg' + ' happ-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')">
                              <p class="m-0">
                                  {{ i.title || value }} 
                              </p>
                            </div>
                      </div>
                  </div>
              </div>
              <p *ngIf="!col.control_type.range || (col.control_type.range && col.control_type.range.length === 0)"
                  class="happ-display-value align-txt-ab happ-full-area"
                  style="width: 100%; height: 80%;"> {{ value }} </p>
          </div>
          <div class="happ-full-area" class="happ-full-area"
              *ngSwitchCase="'List'">
              <div class="happ-display-value"
                  style="width: 100%;"
                  *ngIf="!col.control_type.inline_edit && col.control_type.multiple">
                  <div fxLayout="row wrap"
                      *ngFor="let tt of value | keys; let i=index">
                      <div *ngIf="tt.value"
                          style="border: 1px solid rgba(220,220,220,0.5); padding: 3px; margin: 3px; border-radius: 2px;">
                          {{ tt.key }} </div>
                  </div>
              </div>
              <p class="happ-display-value"
                  style="width: 100%;"
                  *ngIf="!col.control_type.inline_edit && !col.control_type.multiple">
                  {{ value }} </p>
              <div class="happ-full-area"
                  (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)"
                  *ngIf="col.control_type.inline_edit">
                  <ng-container *ngFor="let i of col.control_type.options">
                    <ng-container
                        *ngIf="i.value == value || i.title == value">
                        <div [class]="'happ-full-area happ-inline-button '+(i.background)+'-bg '+(i.color)+'-fg' + ' happ-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')"
                        >
                            <p class="m-0">
                                {{ i.title || i.value }} 
                            </p>
                    </div>
                    </ng-container>
                </ng-container>
              </div>
          </div>
          <div *ngSwitchCase="'reg_value_selection'">
              <p class="happ-display-value"
                  style="width: 100%; height: 100%;"
                  *ngIf="!col.control_type.inline_edit && !col.control_type.multiSelect">
                  <span *ngFor="let i of value.value | keys">
                      {{ i.value }} <br>
                  </span>
              </p>
              <p class="happ-display-value"
                  style="width: 100%; height: 100%;"
                  *ngIf="!col.control_type.inline_edit && col.control_type.multiSelect && isArray(value)">
                  <span
                      style="background: rgba(220,220,220,0.5); margin-right: 5px; padding: 5px"
                      *ngFor="let val of value">
                      <span *ngFor="let i of val.value | keys">
                          {{ i.value }} - </span>
                  </span>
              </p>
          </div>
          <div class="happ-full-area" *ngSwitchCase="'checkbox'">
              <app-checkbox [dtTable]='true'
                  [field]="col.control_type" #val [model]="value">
              </app-checkbox>
          </div>
          <div class="happ-full-area"
              *ngSwitchCase="'text_description'">
              <p
                  *ngIf="col.control_type && !col.control_type.isPopup">
                  {{ value }} </p>
              <div *ngIf="col.control_type.isPopup"
                  style="width: 100%; height: 80%;">
                  <button *ngIf="value"
                      (click)="openDescriptionPopup(col.control_type, value)"
                      mat-icon-button>
                      <mat-icon>info</mat-icon>
                  </button>
              </div>
          </div>
          <div class="happ-full-area" *ngSwitchCase="'file_preview'">
              <button *ngIf="value" (click)="downloadPdf(value)"
                  mat-icon-button>
                  <mat-icon style="color: red;">description</mat-icon>
              </button>
          </div>
          
          <div class="happ-full-area" *ngSwitchCase="'file'">
              <ng-container *ngIf="row.connectedFiles">
                  <div fxLayout="row wrap">
                      <p *ngIf="row.connectedFiles && row.connectedFiles.length" (click)="openFilePopup(row)"  style="cursor: pointer;">
                          {{ row.connectedFiles.length }}
                          {{  'dttable.files' | translate}}
                      </p>
                      <!-- <span class="mr-4" *ngFor="let file of row.connectedFiles">
                          <a (click)="downloadPdf(file._id)"> {{ file.originalName }} </a>
                          <button (click)="downloadPdf(file._id)" [matTooltip]="file.filename" mat-icon-button>
                                <mat-icon style="color: red;">description</mat-icon>
                            </button>
                        </span> -->
                    </div>
              </ng-container>
          </div>
          <div class="happ-full-area" *ngSwitchDefault>
              <p class="happ-display-value"
                  style="display: flex; width: 100%; min-height: 40px; margin: 0px;"
                  *ngIf="col.prop != '_id' && col.control_type && col.control_type.category != 'radio' && !col.control_type.inline_edit">
                  <span
                      *ngIf="(row && row.allSum && col.control_type.allTotal)? true: false">
                      {{ 'dttable.sum' | translate }} </span>
                  {{ (value || value == 0)? value: col.defaultContent }}
              </p>
              <div class="w-100-p"
                  *ngIf="col.prop != '_id' && col.control_type && col.control_type.category == 'radio'">
                  {{ value }} </div>
              <p class="happ-display-value"
                  style="display: flex; width: 100%; min-height: 40px; margin: 0px;"
                  (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)"
                  *ngIf="col.prop != '_id' && col.control_type && col.control_type.category != 'radio' && col.control_type.inline_edit && !inlineEditCell[col.control_type.label+colIndex+rowIndex]">
                  <span
                      *ngIf="(row && row.allSum && col.control_type.allTotal)? true: false">
                      {{ 'dttable.sum' | translate }} </span>
                  {{ (value || value == 0)? value: col.defaultContent }}
              </p>
          </div>
      </div>
  </div>
  <!-- ENDS templating other constoll type -->
  <!-- templating in edit mode -->
  <div style="width: 100%;"
    [class]="'happ-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')"
      *ngIf="col && col.control_type && col.control_type.label  && inlineEditCell[col.control_type.label+colIndex+rowIndex]">
      <div [ngSwitch]="col.control_type.control_type">
          <div *ngSwitchCase="'text'">
              <input *ngIf="col.control_type.inline_edit" #inputVal
                  autofocus class="happ-input"
                  (keyup.enter)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col)"
                  (blur)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), false, col)"
                  [value]="value" type="text">
          </div>
          <div *ngSwitchCase="'number'">
              <input #inputVal autofocus
                  (keypress)="validateNumber($event, inputVal, col.control_type)"
                  type="number" class="happ-full-area happ-input"
                  (keyup.enter)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col)"
                  (blur)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), false, col)"
                  [value]="value">
          </div>
          <div *ngSwitchCase="'currency'">
              <input #inputVal autofocus
                  (keypress)="validateNumber($event, inputVal, col.control_type)"
                  type="number" class="happ-full-area happ-input"
                  (keyup.enter)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col)"
                  (blur)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), false, col)"
                  [value]="value">
          </div>
          <div style="width: 100%;" *ngSwitchCase="'textarea'">
              <textarea
                  *ngIf="inlineEditCell[col.control_type.label+colIndex+rowIndex]"
                  class="happ-input" style="width: 100%;" fxFlex
                  #inputVal autofocus
                  (blur)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), false, col)"
                  (keyup.enter)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col)"
                  style="resize: none;">{{value}} </textarea>
          </div>
          <div class="happ-content" *ngSwitchCase="'button-list'">
              <app-button-list [hideTitle]="true"
                  [field]="col.control_type"
                  (valChange)="updateReg(row._id, inputVal.model, value, col.control_type.label, rowIndex); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col);"
                  #inputVal [model]="value"></app-button-list>
          </div>
          <div class="happ-content" *ngSwitchCase="'List'">
              <app-list [hideTitle]="true" [field]="col.control_type"
                  (valChange)="updateReg(row._id, inputVal.model, value, col.control_type.label, rowIndex); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col);"
                  #inputVal [model]="value"></app-list>
          </div>
      </div>
  </div>
  <!-- ENDS templating in edit mode -->
  <div fxLayout="column"
      style="width: 100%; height: 100%; margin-right: 15px; justify-content: flex-end;"
      *ngIf="datatableService && datatableService.applicationType === 'workspace' && (col.name=='subAction' || col.title === 'subAction')">
      <div>
          <button mat-raised-button (click)="moveToParent(row.parentId)" >
              Parent Record
          </button>
      </div>
      <div *ngFor="let action of appData.subActionList">
          <button class="my-4" mat-raised-button
              *ngIf="action.placement && action.placement.recordActions && action.actionType === 'subAction' && action.typeOfView === 'text'"
              style="text-transform: capitalize;"
              style="margin-left: 12px;"
              (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
              {{ action.default_label | translate }} </button>
          <button class="my-4 happ-icon-raised"
              [matTooltip]="action.default_label | translate"
              mat-icon-button
              *ngIf="action.placement && action.placement.recordActions && action.actionType === 'subAction' && action.typeOfView === 'icon'"
              style="text-transform: capitalize;"
              style="margin-left: 12px;"
              (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
              <mat-icon>{{ action.icon || 'apps'}}</mat-icon>
          </button>
          <button class="my-4" mat-raised-button
              *ngIf="action.placement && action.placement.recordActions && action.actionType === 'subAction' && action.typeOfView === 'textAndIcon'"
              style="text-transform: capitalize;"
              style="margin-left: 12px;"
              (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
              <mat-icon class="mr-4">{{ action.icon || 'apps' }}
              </mat-icon> {{ action.default_label | translate }}
          </button>
      </div>
  </div>
  <div style="width: 100%; height: 100%; margin-right: 15px; justify-content: flex-end;"
      *ngIf="col.name=='Actions' || col.title === 'Actions'"
      fxLayout="row">
      <div fxLayout="row wrap" fxLayoutAlign="end center"
          *ngIf="datatableService && datatableService.applicationType === 'workspace'"
          class="my-4">
          <div *ngFor="let action of appData.mainActionList">
              <button class="my-4" mat-raised-button
                  *ngIf="action.placement && action.placement.recordActions && action.actionType === 'action' && action.typeOfView === 'text'"
                  style="text-transform: capitalize;"
                  style="margin-left: 12px;"
                  (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                  {{ action.default_label | translate }} </button>
              <button class="my-4 happ-icon-raised"
                  [matTooltip]="action.default_label | translate"
                  mat-icon-button
                  *ngIf="action.placement && action.placement.recordActions && action.actionType === 'action' && action.typeOfView === 'icon'"
                  style="text-transform: capitalize;"
                  style="margin-left: 12px;"
                  (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                  <mat-icon>{{ action.icon || 'apps'}}</mat-icon>
              </button>
              <button class="my-4" mat-raised-button
                  *ngIf="action.placement && action.placement.recordActions && action.actionType === 'action' && action.typeOfView === 'textAndIcon'"
                  style="text-transform: capitalize;"
                  style="margin-left: 12px;"
                  (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                  <mat-icon class="mr-4">{{ action.icon || 'apps' }}
                  </mat-icon> {{ action.default_label | translate }}
              </button>
          </div>
          <button *ngIf="appData && !appData.hideEditButton && !(datatableService.params && datatableService.params.hideEditButton)"
              aria-label="datable_workspace_edit" mat-raised-button
              style="text-transform: capitalize;"
              style="margin-left: 12px;"
              (click)="moveTo('./'+row._id)">
              {{ 'dttable.edit' | translate }} </button>
      </div>
      <div style="width: 100%; height: 100%; margin-right: 15px; justify-content: flex-end;"
          *ngIf="datatableService && datatableService.applicationType !== 'workspace'"
          fxLayout="row">
          <div style="width: 100%; justify-content: flex-end;"
              *ngIf="col.name=='Actions' || col.title === 'Actions'"
              fxLayout="row">
              <ng-container *ngIf="!(datatableService.params && datatableService.params.hideEditButton)">
                  <div *ngFor="let button of col.render">
                      <div
                          *ngIf="button.application !== 'app.pinboxOfficeContacts2.dashboards.crm' && button.placement.recordActions">
                          <div *ngIf="button.icon">
                              <button mat-icon-button *ngIf="button.icon"
                                  style="color: black"
                                  (click)="actionMoveTo(rowIndex, button.application,row, button, button.fuse2App)"
                                  color="primary">
                                  <mat-icon>{{button.icon}}</mat-icon>
                              </button>
                          </div>
                          <div *ngIf="!button.icon">
                              <button class="m-8 ml-4"
                                  [attr.aria-label]="button.translations[currentLang] || button.default_label"
                                  mat-raised-button
                                  (click)="actionMoveTo(rowIndex, button.application,row, button, button.fuse2App)">
                                  {{ (button.translations[currentLang] | translate) || (button.default_label | translate)}}
                              </button>
                          </div>
                      </div>
                  </div>
              </ng-container>
              <div>
                  <button *ngIf="datatableService && datatableService.params && datatableService.params.unSubscribeThis"
                  aria-label="datable_workspace_edit" mat-icon-button
                  style="text-transform: capitalize;"
                  style="margin-left: 12px;"
                  [matTooltip]="'dttable.unsubscribe_from_mailing_list' | translate"
                  (click)="removeFromMailingList(row, 'unSubscribeThis')">
                    <mat-icon>unsubscribe</mat-icon>
                   </button>
                  <button *ngIf="datatableService && datatableService.params && datatableService.params.removeEmail"
                  aria-label="datable_workspace_edit" mat-icon-button
                  style="text-transform: capitalize;"
                  style="margin-left: 12px;"
                  [matTooltip]="'dttable.remove_from_mailing_list' | translate"
                  (click)="removeFromMailingList(row, 'removeFromList')">
                    <mat-icon>delete</mat-icon>
                   </button>
                  <button *ngIf="datatableService && datatableService.params && datatableService.params.unBlacklist"
                  aria-label="datable_workspace_edit" mat-icon-button
                  style="text-transform: capitalize;"
                  style="margin-left: 12px;"
                  [matTooltip]="'dttable.unBlacklist_from_mailing_list' | translate"
                  (click)="removeFromMailingList(row, 'unBlackList')">
                    <mat-icon>mail</mat-icon>
                   </button>
              </div>
          </div>
          <div *ngIf="enableMoreActionIcon">
              <button mat-icon-button [matMenuTriggerFor]="actions">
                  <mat-icon> more_vert </mat-icon>
              </button>
              <mat-menu #actions="matMenu">
                  <div *ngFor="let button of col.render">
                      <div
                          *ngIf="button.application !== 'app.pinboxOfficeContacts2.dashboards.crm' && button.placement.dropdownActions">
                          <div *ngIf="button.icon">
                              <button mat-icon-button
                                  *ngIf="button.icon"
                                  style="color: black"
                                  (click)="actionMoveTo(rowIndex, button.application,row, button, button.fuse2App)"
                                  color="primary">
                                  <mat-icon>{{button.icon}}</mat-icon>
                              </button>
                          </div>
                          <div *ngIf="!button.icon">
                              <button mat-menu-item
                                  [attr.aria-label]="button.translations[currentLang] || button.default_label"
                                  color="primary"
                                  (click)="actionMoveTo(rowIndex, button.application,row, button, button.fuse2App)">
                                  {{ (button.translations[currentLang] | translate) || (button.default_label | translate)}}
                              </button>
                          </div>
                      </div>
                  </div>
              </mat-menu>
          </div>
      </div>
  </div>