<div fxLayout="row wrap" fxLayoutGap="10px">
  <div class="mt-8 mr-0 py-4" *ngFor="let action of cardActions | sort: 'sort'" [fxFlex]="action.flexWidth" fxFlex.lt-md="100" [ngSwitch]="action.actionType" style="padding: 0px 10px; margin: 0px !important" >
    <!-- horizontal buttons -->
    <div fxLayout="center center" class="mt-8" fxLayout="row" fxFlex="100" *ngSwitchCase="'horizontalButtons'">
      <p *ngIf="action.showLabel" style="margin: 3px 20px 0px 7px;"> {{ action.name | translate }} </p>
      <div fxLayout="row wrap" >
        <ng-container *ngFor="let option of action.options | sort: 'sort'">
          <div>
            <button mat-raised-button class="happ-small-button" [color]="(rowData && rowData[action.keyName] === option.value) ? 'primary' : ''" (click)="optionChanged(option, action)" >{{ option.name | translate }}</button>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- next default buttons -->
    <div fxLayout="center center" fxLayout="column" fxFlex="100" *ngSwitchCase="'nextActionButtons'">
      <p *ngIf="action.showLabel" style="margin: 3px 20px 0px 7px;"> {{ action.name | translate }} </p>
      <div style="width: 100%;" fxLayout="row wrap" >
        <button style="width: 100%;" mat-raised-button [ngClass]="['happ-small-button', action.actionClass]"  (click)="nextActionClicked(action)" >{{ action.name | translate }}</button>
      </div>
    </div>
    <!-- input -->
    <div fxLayoutAlign="start center" fxLayout="row" *ngSwitchCase="'input'" fxFlex="100">
      <mat-form-field class="happ-small-form-field-outline" floatLabel="always" floatLabel="always" appearance="outline" fxFlex="100">
        <mat-label>{{ action.name | translate }}</mat-label>
        <input #tempInput [disabled]="action.disabled" [(ngModel)]="rowData[action.keyName]" (blur)="inputChange(tempInput.value, action)" matInput placeholder="">
      </mat-form-field>
    </div>

    <!-- input number -->
    <div fxLayoutAlign="start center" fxLayout="row" *ngSwitchCase="'input-number'" fxFlex="100">
      <mat-form-field class="happ-small-form-field-outline" floatLabel="always" floatLabel="always" appearance="outline" fxFlex="100">
        <mat-label>{{ action.name | translate }}</mat-label>
        <input #tempInput type="number" [disabled]="action.disabled" [(ngModel)]="rowData[action.keyName]" (blur)="inputChange(tempInput.value, action)" matInput placeholder="">
      </mat-form-field>
    </div>
    <!-- editor -->
    <div fxLayoutAlign="start center" fxLayout="column" style="position: relative;" *ngSwitchCase="'editor'" fxFlex="100">
      <p class="happ-placeholder" *ngIf="!rowData[rowData['_id']+'_'+action.keyName]">{{ action.name | translate }}</p>
      <div *ngIf="!rowData[rowData['_id']+'_'+action.keyName]" (click)="rowData[rowData['_id']+'_'+action.keyName]=true" class="happ-html-preview-area" [innerHTML]="rowData[action.keyName] | parseHtmlContent:rowData | htmlWithStyle">
      </div>
      <div style="border: 1px solid #e0e0e0; border-bottom: 0px;" fxLayout="row" class="w-100-p" *ngIf="rowData[rowData['_id']+'_'+action.keyName]" >
        <div>
          <p class="m-0 ml-8 font-weight-700" style="margin-top: 10px !important;">
            {{ action.name | translate }}
          </p>
        </div>
        <span fxFlex></span>
        <div fxLayout="row">
          <div style="border-left: 1px solid #e0e0e0;">
            <button color="warn" mat-icon-button (click)="closeEditor(action)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div style="border-left: 1px solid #e0e0e0;">
            <button class="happ-square-button" style="background: lightgreen;" mat-icon-button (click)="editorChange(rowData[action.keyName], action)">
              <mat-icon>check</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <ck-editor *ngIf="rowData[rowData['_id']+'_'+action.keyName]" name="editor1" class="w-100-p" [config]="config" [(ngModel)]="rowData[action.keyName]" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
    </div>
    <!-- date -->
    <div fxLayoutAlign="start center" fxLayout="row" *ngSwitchCase="'date'" fxFlex="100">
      <mat-form-field class="happ-small-form-field-outline" floatLabel="always" floatLabel="always" appearance="outline" fxFlex="100">
      <mat-label> {{ action.name | translate }} </mat-label>
      <input matInput #tempInput [matDatepicker]="picker" (click)="picker.open()" (dateChange)="inputChange(tempInput.value, action)" [(ngModel)]="rowData[action.keyName]">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    </div>
    <!-- link -->
    <div fxLayoutAlign="start center" fxLayout="row" *ngSwitchCase="'link'" fxFlex="100">
      <div *ngIf="rowData[action.keyName];else blankLink">
        <a style="cursor: pointer;" (click)="openLink(rowData[action.keyName])" > {{ rowData[action.keyName] }} </a>
      </div>
      <ng-template #blankLink></ng-template>
    </div>
    <!-- others - select -->
    <div *ngSwitchDefault fxFlex="100">
        <mat-form-field class="happ-small-form-field-outline" floatLabel="always" floatLabel="always" appearance="outline" fxFlex="100">
            <mat-label>{{ action.name | translate }}</mat-label>
            <mat-select [disabled]="action.disabled" [(ngModel)]="rowData[action.keyName]" (selectionChange)="inputChange(rowData[action.keyName], action)">
                <mat-option *ngFor="let option of action.options | sort: 'sort'" [value]="option.value">
                  {{ option.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
  </div>
</div>