import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { DatatableService } from '../../../datatable.service';

@Component({
  selector: 'app-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CardActionsComponent implements OnInit {

  @Input() cardActions: any[];
  @Input() rowData: any = {};
  @Output() optionClicked: EventEmitter<Object> = new EventEmitter();  

  selectedValue;
  isEdit = false;
  config = {
    height: 150,
    toolbar: [
    [ 'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule' ]
]};
  oldValue = {};

  constructor(
    private _helperService: HelpersService,
    private translationService: TranslateService,
    public datatableService: DatatableService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _adapter: DateAdapter<any>
  ) { }

  ngOnInit() {
    this.cardActions = this.cardActions || [];
    this._adapter.setLocale(this.translationService.getDefaultLang());

    this.cardActions = this.cardActions.filter((ele) => (ele && ele.isActive));
    this.oldValue = JSON.parse(JSON.stringify(this.rowData));
    this.cardActions.forEach((action) => {
      if (action && action.actionType === 'editor') {
        this.rowData[`${this.rowData['_id']}_${action.keyName}`] = this.checkForEditMode(action);
      }
    });
  }

  openLink(link: string) {
    window.open(link, '_blank', 'location=yes,height="100%",width="100%",top="10px",left="10px",scrollbars=yes,status=yes');
  }

  checkForEditMode(action: any) {
    if (action && this.rowData[action.keyName] && action.actionType === 'editor') {
      return false;
    }
    return true;
  }

  optionChanged(option: any, action: any) {
    option.keyName = action.keyName;
    this.optionClicked.emit(option);
    option.recId = this.rowData._id;
    this.datatableService.onCardViewChange.next(option)
    if (!this._changeDetectorRef['destroyed']) {
      this._changeDetectorRef.detectChanges();
    }
  }

  async nextActionClicked(action: any) {
    if (this.rowData.processStatusId) {
      this.optionChanged({value: "loading", doNotUpdate: true}, action);
      try {
        const tempResp = await this._helperService.getRequest(`api/reg-process/${this.rowData['_id']}/nextDefaultAction?currentAct=${this.rowData.processStatusId}`)
        let extraData: any = {};
        
        if (tempResp && tempResp.type === "info") {
          this._helperService.showInfo(`global.${tempResp.message}`, "Info", "info");
          this.optionChanged({value: "done", doNotUpdate: true}, action);
        } else {
          extraData = tempResp;
          this._helperService.showInfo(`global.process_success`, "Success", "success");
          this.optionChanged({value: "done", doNotUpdate: true, extraData}, action);
        }
  
  
      } catch (err) {
        this.optionChanged({value: "done", doNotUpdate: true}, action);
      }
    } else {
      this._helperService.showInfo(`global.no_process_status_id_in_form`, "Info", "info");

    }
  }

  closeEditor(action: any) {
    this.rowData[`${this.rowData['_id']}_${action.keyName}`] = this.checkForEditMode(action);
  }

  editorChange(value: any, action: any) {
    this.rowData[`${this.rowData['_id']}_${action.keyName}`] = this.checkForEditMode(action);
    this.optionChanged({ value }, action);
  }

  inputChange(value: any, action: any) {
    this.oldValue[action.keyName] = this.oldValue[action.keyName] || '';
    if (value !== this.oldValue[action.keyName]) {
      this.oldValue[action.keyName] = value;
      this.optionChanged({ value }, action);
    }
  }
}
