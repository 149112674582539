import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation, FuseNavigationMenuService, CLIENT_PANEL_NAV, CLIENT_PANEL_THEME } from 'app/navigation/navigation';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/main/content/pages/authentication/auth.service';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { SystemInfoComponent } from 'app/main/content/pages/system-info/system-info.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SocketService } from 'app/main/content/services/socket/socket.service';
import { InvitationListService } from 'app/main/content/pages/invitation-list/invitation-list.service';
import { PlatformIssueReporterComponent } from 'app/main/content/global-component/platform-issue-reporter/platform-issue-reporter.component';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { ADMIN_EMAILS } from '../../../main/content/consts/adminConfig.const';
import { FuseLayoutSettingsConst } from '../../../main/content/consts/fuseLayoutSettings.const';
import { IOfficeData } from '../../../main/content/interfaces/office.interface';
import { AuthQuery } from 'app/main/content/state/authState/auth.query';
import { AuthStore } from 'app/main/content/state/authState/auth.store';
import { OfficeStore } from 'app/main/content/state/officeState/office.store';
import { OfficeQuery } from 'app/main/content/state/officeState/office.query';
import { WorkspaceStore } from 'app/main/content/state/workspaceState/workspace.store';
import { WorkspaceQuery } from 'app/main/content/state/workspaceState/workspace.query';
import { PlatformSettings } from './platform-settings.const';
import { environment } from 'environments/environment';
import { PlatformLayoutComponent } from 'app/main/content/components/platform-layout/platform-layout.component';
import { OfficeListPopupComponent } from 'app/main/content/apps/office-list-popup/office-list-popup.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import * as introJs from 'intro.js/intro.js';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{

    introJS = introJs();
    private unsubscribe = new Subject<void>();
    isLoggedIn = false;
    hideToolbar = false;
    isOfficeView = true;
    loginByPassUrls = [
        '/forgot-password',
        '/cp-login',
        '/register',
        '/login'
    ];
    loginByPassUrlContains = [
        '/userInvitation',
        '/mail-detail',
        '/checkout',
        '/provider-client',
        '/set-password',
        '/changepassword'
    ];

    layouts = FuseLayoutSettingsConst || [];

    defaultWorkspaceConfig: any = {
        showWorkspace: true,
        workspaceList: [],
        selectedWorkspace: {}
    };

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    officeData: IOfficeData;
    hiddenNavbar: boolean;
    isProvider: boolean = false;
    languages = [
        {
            'id': 'en',
            'title': 'English',
            'flag': 'us'
        },
        {
            'id': 'pl',
            'title': 'Polish',
            'flag': 'pl'
        }
    ];
    invitationCount = 0;
    navigation: any;
    routeParams: any;
    selectedLanguage: any;
    ADMIN_EMAILS = ADMIN_EMAILS;

    // Private
    private _unsubscribeAll: Subject<any>;

    officeSettings: any = [];
    
    showLoadingBar: boolean;
    horizontalNav: boolean;
    username: any;
    selectedOfficeName: String;
    home = '/dashboard';
    officeList: Array<object>;
    toolBarShortcuts = [];

    subscription: Subscription;
    version: any;
    error: any;
    changedOfficeName = null;
    selectedWorkspaceName = '';
    currentUrl: string;
    testDate: any = Date.now();
    officeCredit = 0;
    virtualOrgObj: string;

    constructor(
        private router: Router,
        private authService: AuthService,
        public navigationService: FuseNavigationMenuService,
        public dialog: MatDialog,
        private helperService: HelpersService,
        private profileHelperService: ProfileHelperService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        public socketService: SocketService,
        private _invitationService: InvitationListService,
        private _officeHelper: OfficeHelpersService,
        private _fuseNavigationService: FuseNavigationService,
        private authQuery: AuthQuery,
        private authStore: AuthStore,
        private officeStore: OfficeStore,
        private workspaceStore: WorkspaceStore,
        private officeQuery: OfficeQuery,
        private workspaceQuery: WorkspaceQuery,
        private _changeDetectorRef: ChangeDetectorRef,
    )
    {
        this.checkLoginAndToken();
        this.officeSettings = JSON.parse(JSON.stringify(PlatformSettings));
        if (this.getLocalStorageToken()) {
            this.getOfficeList();
        }
        this.processInitOperations()
        .then(() => {

        }).catch(err => {
            console.info(err);
        });
    }

    async getToolbarShortcuts () {
        try {
            const profile = await this.profileHelperService.getLocalProfileData();
            const currentUser = await this._officeHelper.getOfficeUser(profile.profileId);
            if (currentUser) {
                this.toolBarShortcuts = currentUser['toolbarShortcut'] || [];
            }
        } catch (error) {
            throw error;
        }
    }

    getSelectedLanguage() {
        try {
            this.selectedLanguage = this.languages[0];
            if (localStorage.getItem('selectedLanguage')) {
                this.languages.forEach(lang => {
                    if (lang.id === localStorage.getItem('selectedLanguage')) {
                        this.selectedLanguage = lang;
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    isByPassUrl(url: string): boolean {
        console.log("222222");
        let temp = false;
        this.loginByPassUrls.forEach((ele) => {
            if (ele === url) {
                temp = true;
            }
        });
        this.loginByPassUrlContains.forEach((ele) => {
            if (url.includes(ele)) {
                temp = true;
            }
        });
        console.log("is by pass", temp);
        return temp;
    }

    ngOnInit() {
        this.introJS.setOptions({
            steps: [
            {
               element: '#step1',
               intro: 'Welcome to your new app!',
               position: 'bottom'
            },
            {
               element: '#step2',
               intro: "Ok, wasn't that fun?",
               position: 'right'
            },
            {
               element: '[aria-label="open_office_dropdown"]',
               intro: "let's keep going",
               position: 'top'
            },
            {
               element: '#step4',
               intro: 'More features, more fun.',
               position: 'right'
            }
         ],
         showProgress: true
        });
        // setTimeout(() => {
        //     this.introJS.start();
        // }, 1500);

        this.navigationService.$virtualOrgValue.subscribe((changed) => {
            let tempVal = localStorage.getItem("lastSelectedOrg");
            tempVal = JSON.parse(tempVal);
            this.virtualOrgObj = tempVal
        })

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((ele: any) => {
                if (ele.url.includes("/cp") && window.location.pathname !== '/cp-login') {
                    // switch theme.
                    this.isOfficeView = false;
                    this._fuseNavigationService.unregister('main');
                    if (window.location.pathname.includes("/cp/specialist") && window.location.pathname !== '/cp-login') {
                        this._fuseNavigationService.register('main', this.navigationService.SPECIALIST_PANEL_NAV);
                    } else if (ele.url.includes("/cp") && window.location.pathname !== '/cp-login') {
                        this._fuseNavigationService.register('main', this.navigationService.CLIENT_PANEL_NAV);
                    }
                    this._fuseNavigationService.setCurrentNavigation('main');
                    const temp = JSON.parse(JSON.stringify(this.navigationService.CLIENT_PANEL_THEME))
                    // temp.logout = this.logOut;
                    this._fuseConfigService.setConfig(temp);
                    this.profileHelperService.getLocalProfileData()
                    .then((data) => {
                        if (data.privateAreaOfficeId) {
                            this._officeHelper.getOfficeData(data.privateAreaOfficeId)
                            .then((officeDoc) => {
                                localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeDoc));
                                localStorage.setItem('selectedOfficeId', JSON.stringify(officeDoc._id));
                            }).catch(err => {
                                console.error(err);
                            })
                        }
                    }).catch(err => {
                        console.error(err);
                    })
                    // this._fuseConfigService.config = temp;
                } else if (window.location.pathname.includes('/mail-detail')) {
                    // skip
                } else {
                    if (ele.url.startsWith("/workspace/")) {

                        if (this.officeData.projects && this.officeData.projects.multiOrganizationOffice) {

                            setTimeout(() => {
                                const flatNavigation = this._fuseNavigationService.getFlatNavigation(this._fuseNavigationService.getCurrentNavigation())
                                .map((ele) => (ele.url));
    
                                if (ele.url.split("/").length === 3) {
                                    ele.url = `${ele.url}/dashboard`;
                                }
    
                                const t3 = _.some(flatNavigation, (ele2) => (ele.url.startsWith(ele2)));
                                if (!t3) {
                                    this.router.navigateByUrl(`/offices/${(this.officeData['_id'])}/dashboard`)
                                    return;
                                }
                            }, 8000);
                        }

                    }

                    if (ele.url.startsWith("/workspace/") && this.officeData.projects && this.officeData.projects.multiOrganizationOffice) {
                        if (!localStorage.getItem("selectedVirtualOrgId")) {
                            this.router.navigateByUrl(`/offices/${(this.officeData['_id'])}/organizations`)
                        }
                    } else if (!this.isOfficeView) {
                        this.isOfficeView = true;
                        if (this.officeData._id) {
                            this.changeOffice(this.officeData._id);
                        }
                    } else {
                        if (ele.url === "/login" && this.officeData['_id']) {
                            // this.router.navigateByUrl(`/offices/${(this.officeData['_id'])}/dashboard`)
                            this.logOut();
                        }
                    }
                }
        });

        this.authQuery.email.pipe(takeUntil(this.unsubscribe)).subscribe((email) => {
            console.log("loggin in = ",email);
            this.username = email;
        });

        this.authQuery.officeName.pipe(takeUntil(this.unsubscribe)).subscribe((officeName) => {
            window.localStorage.removeItem("lastSelectedOrg")
            this.selectedOfficeName = officeName;
            this.checkWorkspaceAndOfficeRoutes();
            this.getInvitationCount();
        });

        this.authQuery.isAdmin.pipe(takeUntil(this.unsubscribe)).subscribe((isAdmin) => {
            console.log("Is admin check ", isAdmin);
            this.navigationService.isAdmin = isAdmin;
        });

        this.authQuery.invitationCount.pipe(takeUntil(this.unsubscribe)).subscribe((invitationCount) => {
            this.invitationCount = invitationCount;
        });

        if (this.authService.isLoggedIn()) {
            this.socketService.reConnectSocket();
        }

        this.authQuery.isAuthenticated.pipe(takeUntil(this.unsubscribe)).subscribe((isAuthenticated) => {
            this.isLoggedIn = isAuthenticated;
        });

        this.authQuery.redirectUrl.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
            // (console as any).routeLog("redirect url", url);
            if (url) {
                this.router.navigate([url]);
            }
        });

        this.officeQuery.officeDoc.pipe(takeUntil(this.unsubscribe)).subscribe((officeData) => {
            if (officeData.type === "privateArea") {
                return;
            }
            this.officeData = JSON.parse(window.localStorage.getItem("ngStorage-selectedOffice"));
            this.isProvider = false;
            if (this.officeData && this.officeData.sharedServiceSettings && this.officeData.sharedServiceSettings.status === "active") {
                this.isProvider = true;
            }
            console.log("MOVING - BEFORE = ", this.officeData);
            if (this.officeData.projects && this.officeData.projects.multiOrganizationOffice) {
                if (!localStorage.getItem("selectedVirtualOrgId")) {
                    this.router.navigateByUrl(`/offices/${(this.officeData['_id'])}/organizations`)
                }
            }
            this.selectedOfficeName = officeData.name;
            this.checkWorkspaceAndOfficeRoutes();
            this.getOfficeCredit();

            if (!window.location.pathname.startsWith("/workspace/")) {
                this._changeDetectorRef.detectChanges();
    
                this.navigationService.resetValue();
                this.selectedWorkspaceName = 'toolbar.dashboard';
                this.navigationService.refreshMenuItems();
            }

        });

        this.workspaceQuery.workspaceDoc.pipe(takeUntil(this.unsubscribe)).subscribe((workspaceData) => {
            this._changeDetectorRef.detectChanges();
            if (workspaceData && this.officeData._id === workspaceData.officeId) {
                this.selectedWorkspaceName = (workspaceData && workspaceData.name) || 'toolbar.dashboard';
            }
        });

        this.getSelectedLanguage();
        window.onfocus = () => {
            this._officeHelper.getLocalOfficeData().then((officeData) => {
                if (!window.location.pathname.includes("/cp/") && this.selectedOfficeName !== officeData.name) {
                    this.changedOfficeName = officeData.name;
                    this._fuseConfigService.config = {
                        layout: {
                            navbar: {
                                hidden: true,
                                folded: true
                            },
                            toolbar: {
                                hidden: false,
                                position: 'above'
                            }
                        }
                    };
                } else {
                    this.changedOfficeName = null;
                }
            }).catch(err => {
                this.changedOfficeName = null;
            });
        };
        
        this._unsubscribeAll = new Subject();

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

            const langId = localStorage.getItem('selectedLanguage');

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': langId || this._translateService.currentLang});

        this.version = environment.version;

        this.handleLoaderOnNavigation();
    }

    changeLayout(settings: any) {
        this._fuseConfigService.setConfig({
            layout: settings
        });

        this.updateOfficeUserWithLayoutSettings();
    }

    getInvitationCount() {
        if (this.isLoggedIn) {
            this.helperService.getRequest('api/offices/user-invitation/count')
            .then((data) => {
                this.invitationCount = data;
            }).catch(err => {
                console.log(err);
            });
        }
    }

    async updateOfficeUserWithLayoutSettings() {
        try {
            const profile = await this.profileHelperService.getLocalProfileData();
            let updated = false;            
            const officeData = await this._officeHelper.getLocalOfficeData();
            officeData.users = officeData.users || [];
            officeData.users.forEach((ele) => {
                if (profile && profile.profileId && ele && ele.id && ele.id === profile.profileId) {
                    ele.aotFuseSetting = this._fuseConfigService.configValue;
                    updated = true;
                }
            });
            if (updated) {
                await this._officeHelper.updateOfficeDoc({ users: officeData.users });
                this.helperService.showInfo('info_and_errors.layout_updated_and_saved');
            }
        } catch (err) {
            console.info(err);
        }
    }

    moveToDashboard() {
        if (this.officeData && this.officeData.type !== "privateArea") {
            this.router.navigate([`/offices/${this.officeData._id}/dashboard`]);
        }
    }

    moveTo(setting: any) {
        if (setting && setting.fuse2Url) {
            const url = setting.fuse2Url.replace(':officeId', this.officeData._id);
            this.router.navigate([url]);
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    logOut() {
        this.username = undefined;
        this.authService.logOut();
        this.authStore.reset();
        this.officeStore.reset();
        this.workspaceStore.reset();
        this.selectedWorkspaceName = '';
        this.selectedOfficeName = '';
        this.socketService.disconnectSocket();
        console.log("11111");
        this.router.navigate(['/login']);
    }

    addIssue(type): void {
        this.dialog.open(PlatformIssueReporterComponent, {
          width: 'max-content',
          maxWidth: '80%',
          height: 'max-content',
          restoreFocus: false,
          panelClass: 'happ-form-builder-dialog',
          data: { reporterType: type }
        });
      }

    changeOffice(office) {
        
        if (office) {
            this._officeHelper.loadNewOffice(office['_id'] || office['officeId'] || office)
            .then(data => {
      console.log("Moving = officeDoc", data);

                this.virtualOrgObj = null;
                if (data['_id']) {
                    console.log("organizations = ");
                    this.authStore.update({ officeId: (data['_id']), officeName: data.name, redirectUrl: `/offices/${(data['_id'])}/dashboard` });
                    console.log("moving before 22")
                    if (data.projects && data.projects.multiOrganizationOffice) {
                        this.router.navigateByUrl(`/offices/${(data['_id'])}/organizations`)
                    } else {
                        this.router.navigateByUrl(`/offices/${(data['_id'])}/dashboard`);
                    }
                    this.officeStore.reset()
                    this.officeStore.update(data);
                    this.authService.onOfficeChange.next(data['_id']);
                }
            })
            .catch(err => {
                console.info(err);
            });
        }
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        
        // Use the selected language for translations
        this._translateService.use(lang.id);
        this._translateService.setDefaultLang(lang.id);
        localStorage.setItem("selectedLanguage", lang.id);
        this.updateProfileForLang(lang);
    }

    updateProfileForLang(lang) {
        this.helperService.updateProfileData({ defaultLang: lang.id })
        .catch(err => {
            this.helperService.openSnackBar(err);
        });
    }
    
    getOfficeList(): void
    {
        if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['offices']) {
                this.officeList = JSON.parse(localStorage.getItem('ngStorage-profile'))['offices'];
        } else {
            this.error = 'some error occured';
        }
    }

    systemInfo(): void {
        this.dialog.open(SystemInfoComponent, {
            width: '60%',
            height: '60%',
              panelClass: 'happ-form-builder-dialog',
            data: { }
        });
    }

     /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._fuseSidebarService.getSidebar('navbar').open();
    }

    handleWorkspaceMenuData(data: any = {}) {
        try {
            if (data && data.type) {
                {

                    this.defaultWorkspaceConfig = this.defaultWorkspaceConfig || this.navigationService.defaultWorkspaceConfig || {};
                    switch (data.type) {
                        case 'workspace':
            console.log("WorkspaceDebug 22222")
                            this.selectedWorkspaceName = (data.selectedWorkspace && data.selectedWorkspace.name) || 'toolbar.dashboard';
                            break;
                        case 'workspaceList':
                            this.defaultWorkspaceConfig.workspaceList = data.workspaceList;
                            break;
                        case 'isVisible':
                            this.defaultWorkspaceConfig.showWorkspace = data.showWorkspace;
                            break;
                    }
                }
            }
        } catch (error) {
            throw error;
        }
    }

    async processInitOperations() {
        try {
            
            // get all active languages from backend
            this.languages = this.helperService.formatTheLanguageArray(await this.helperService.getActiveLangs());

            // init default workspace config
            this.defaultWorkspaceConfig = this.navigationService.defaultWorkspaceConfig || this.defaultWorkspaceConfig;

            // handle change in workspace and list of workspace
            this.navigationService.$workspaceConfig.subscribe((data) => { this.handleWorkspaceMenuData(data); });

            const loc = window.location.pathname;

            this.navigation = navigation;

            // Set the private defaults
            this._unsubscribeAll = new Subject();

            this.translationLoader.loadTranslationsV2(await this.helperService.getTrans('toolbar'));

            this._invitationService.invitationCountChanged.subscribe((data) => {
                this.getInvitationCount();
                if (data) {
                    this.invitationCount = data;
                }
            });

            this.socketService.inviationNotify().subscribe( (data) => {
                this.invitationCount = (data && data.inviatationCount) || 0;
            });


        } catch (error) {
            throw error;
        }
    }

    handleLoaderOnNavigation() {
        this.router.events.subscribe((event) => {   
            if ( event instanceof NavigationStart )
            {
                this.showLoadingBar = true;
            }
            if ( event instanceof NavigationEnd )
            {
                if (event.url) {
                    this.currentUrl = event.url;
                }
                this.checkWorkspaceAndOfficeRoutes();
                this.showLoadingBar = false;
            }
    }, err => {
        this.showLoadingBar = false;
    }, () => {
        this.showLoadingBar = false;
    });

    }

    openLayoutPopup(): void {
        const temp = this.dialog.open(PlatformLayoutComponent, {
            width: 'max-content',
            maxWidth: '80%',
            height: 'max-content',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog'
        });

        temp.afterClosed()
        .subscribe((response) => {
            if (response) {
                switch (response) {
                    case 'no_menu_layout':
                        this.changeLayout(this.layouts[1].settings);
                        break;
                    case 'small_icon_layout':
                        this.changeLayout(this.layouts[3].settings);
                    break;
                    case 'icon_with_text_layout':
                        this.changeLayout(this.layouts[2].settings);
                    break;
                }
            }
        });
    }
    
    checkWorkspaceAndOfficeRoutes() {
        this.currentUrl = this.currentUrl || window.location.pathname;
        if (this.officeData && (this.currentUrl && (!this.currentUrl.startsWith('/workspace/') && !(this.currentUrl === `/offices/${this.officeData._id}/dashboard`)))) {
            // this.navigationService.resetValue();
            // this.selectedWorkspaceName = 'toolbar.out_of_workspace_routes';
        }
        if (this.officeData && this.currentUrl && (this.currentUrl === `/offices/${this.officeData._id}/dashboard` || this.currentUrl === `/offices/${this.officeData._id}/organizations`)) {
            this.selectedOfficeName =  this.officeData.name || '';
            this.selectedWorkspaceName = 'toolbar.dashboard';
        }
        if (this.currentUrl && this.currentUrl === '/personal-dashboard') {
            this.selectedOfficeName = 'dashboard.personal_dashboard';
            // this.hideToolbar = true;
        } else {
            // this.hideToolbar = false;
        }

    }

    openOfficeList() {
        this.dialog.open(OfficeListPopupComponent, {
            width: '100%',
            height: '100%',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: { changeOffice: true }
        });
        
    }
    
    getCookie(name: string) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');
        if (parts.length === 2) { return parts.pop().split(';').shift(); }
    }

    getLocalStorageToken() {
        if (localStorage.getItem('ngStorage-token')) {
            const temp = JSON.parse(localStorage.getItem('ngStorage-token'));
            return temp.token;
        } else {
            return null;
        }
    }

    checkLoginAndToken() {
        const cookieToken = this.getCookie('token');
        const localStorageToken = this.getLocalStorageToken();
        if (cookieToken) {
            if (localStorageToken !== cookieToken) {
                this.authService.afterLogin(cookieToken);
            } else if (!localStorageToken) {
                this.authService.afterLogin(cookieToken);
            }
        }
    }

    async getOfficeCredit() {
        if (this.officeData._id && localStorage.getItem('selectedOfficeId')) {
            const data = await this.helperService.getRequest(`api/office-credit/${this.officeData._id}`);
            data.promoCredits = data.promoCredits || 0;
            data.purchasedCredits = data.purchasedCredits || 0;
            this.officeCredit = data.totalAmount || 0;
        }
    }

    moveToVirtualOrg() {
        this.router.navigateByUrl(`/offices/${(this.officeData['_id'])}/organizations`)
    }

}
