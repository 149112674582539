import { Pipe, PipeTransform } from '@angular/core';
import * as objectPath from 'object-path';

@Pipe({name: 'parseHtml'})
export class ParseHtmlPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string} property
     * @returns {any}
     */
    transform(textToReplace: string = '', translateData: any): any
    {
        const regExp = /##([\s\S]*?)##/g;
        if (regExp.test(textToReplace)) {
            // parse the content.
            return textToReplace.replace(regExp, (_match, contents, _offset, _input_string) => {
                return this.getByKey(translateData, contents);
            });
        } else {
            return textToReplace;
        }
    }

    getByKey(recordData: any, contents: any) {
        if (objectPath.get(recordData, contents) !== undefined && objectPath.get(recordData, contents) !== null) {
            return objectPath.get(recordData, contents);
        } else if (recordData[contents] !== undefined && recordData[contents] !== null) {
            return recordData[contents];
        }
        return '';
    }
}
