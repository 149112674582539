import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit
{
    public confirmMessage: string;
    public inputValue: string;
    public message: string;
    public inputPlaceholder: string;
    public isRequired: boolean;
    public inputType: any = "input";

    errorMessage = '';

    constructor(public dialogRef: MatDialogRef<InputDialogComponent>) { }

    ngOnInit() { }

    saveData() {
      if (this.isRequired && !this.inputValue) {
        this.errorMessage = 'popup.field_is_required';
      } else {
        this.errorMessage = '';
        this.dialogRef.close(this.inputValue);
      }
    }

}
