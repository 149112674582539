import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as moment from 'moment';
// import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() start: any;
  @Input() year: any;
  @Output() startChange: EventEmitter<Object> = new EventEmitter();
  @Output() yearChange: EventEmitter<Object> = new EventEmitter();
  @Input() end: any;
  @Output() endChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  public errorMessage = '';

  constructor() { }

  ngOnInit() {
    if (!this.start || !this.end ) {
      this.errorMessage = 'Error';
    }
  }

  checkError(err) {
    console.log("error", err);
    this.startChange.next(this.start);
    this.endChange.next(this.end);
    if (this.start) {
      this.year = Number(moment(this.start).format("YYYY"));
    }
    this.yearChange.next(this.year);

    if (err && !err['error'] && this.start && this.end) {
      this.errorMessage = '';
    } else {
      this.errorMessage = 'Error';
    }

    if (moment(this.start).isAfter(moment(this.end))) {
      this.errorMessage = 'ct.start_date_is_after_end_error';
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
    } else {
      this.errorMessage = '';
        this.onError.emit({
          error: false,
          label: this.field['label']
        });
    }

    if (this.field.currentYearValidation) {
      const startYear = moment(this.start).format("YYYY");
      const endYear = moment(this.end).format("YYYY");
      if (startYear === moment().format("YYYY") && endYear === moment().format("YYYY")) {
        this.errorMessage = '';
        this.onError.emit({
          error: false,
          label: this.field['label']
        });
      } else {
        this.errorMessage = 'ct.date_range_current_year_error';
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }


  }
}
